import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { 
  collection, 
  getDocs, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  query, 
  where,
  increment,
  writeBatch,
  getDoc,
  setDoc,
  arrayUnion
} from 'firebase/firestore';
import { Contact, Segment, ContactSegment, GuestList, Guest } from 'src/@types/contacts';
import { DB, FUNCTIONS } from 'src/contexts/FirebaseContext';
import Papa from 'papaparse';
import { generateContactId } from 'src/utils/uuidv4';


const mapHeaderToProperty = (header: string): keyof Contact | null => {
    const lowerHeader = header.toLowerCase().trim();
    const mapping: { [key: string]: keyof Contact } = {
      'email': 'email',
      'e-mail': 'email',
      'nom': 'nom',
      'name': 'nom',
      'age': 'age',
      'genre': 'genre',
      'gender': 'genre',
      'region': 'region',
      'dernier achat': 'dernierAchat',
      'last purchase': 'dernierAcha',
      'total depense': 'totalDepense',
      'total spent': 'totalDepense',
      'nombre evenements': 'nombreEvenements',
      'number of events': 'nombreEvenements',
      'abonne': 'abonne',
      'subscribed': 'abonne'
    };
    return mapping[lowerHeader] || null;
  };

// Helper function to convert string to appropriate type
const convertValue = (value: string, key: keyof Contact): any => {
    switch (key) {
      case 'age':
      case 'nombreEvenements':
        return parseInt(value) || 0;
      case 'totalDepense':
        return parseFloat(value) || 0;
      case 'abonne':
        return value.toLowerCase() === 'true' || value === '1';
      default:
        return value;
    }
  };

// Create the API
export const ContactsApi = createApi({
  reducerPath: 'contactsApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Contacts', 'Segments', 'Guests', 'GuestLists'],
  endpoints: (builder) => ({
    // Contacts
    getContacts: builder.query<Contact[], string>({
      async queryFn(userId) {
        try {
          const contactsRef = collection(DB, 'contacts');
          const q = query(contactsRef, where('userId', '==', userId));
          const querySnapshot = await getDocs(q);
          const contacts: Contact[] = [];
          querySnapshot.forEach((doc) => {
            contacts.push({ id: doc.id, ...doc.data() } as Contact);
          });
          return { data: contacts };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      providesTags: ['Contacts'],
    }),

    addContact: builder.mutation<Contact, Omit<Contact, 'id'>>({
      async queryFn(contactData) {
        const batch = writeBatch(DB);
        try {
          // Ensure userId and email are present
          if (!contactData.userId || !contactData.email) {
            throw new Error('userId and email are required fields');
          }

          const normalizedEmail = contactData.email.trim().toLowerCase();
          const contactId = generateContactId(normalizedEmail);
          console.log(contactId);
          const contactRef = doc(DB, 'contacts', contactId);

          // Check if a contact with the same ID exists
          const existingContact = await getDoc(contactRef);

          let finalContactData: Contact;

          if (existingContact.exists()) {
            // Merge existing contact with new data
            finalContactData = { 
              ...existingContact.data() as Contact, 
              ...contactData, 
              id: contactId, 
              email: normalizedEmail 
            };
            batch.set(contactRef, finalContactData, { merge: true });
          } else {
            // Insert new contact
            finalContactData = { 
              ...contactData, 
              id: contactId,
              userId: contactData.userId, 
              email: normalizedEmail 
            };
            batch.set(contactRef, finalContactData);

            // Increment user's contact count only for new contacts
            const userRef = doc(DB, 'users', contactData.userId);
            batch.update(userRef, { contactCount: increment(1) });
          }

          await batch.commit();
          
          return { data: finalContactData };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['Contacts'],
    }),
    updateContact: builder.mutation<void, Contact>({
      async queryFn(contact) {
        try {
          const { id, ...updateData } = contact;
          await updateDoc(doc(DB, 'contacts', id), updateData);
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['Contacts'],
    }),

    deleteContact: builder.mutation<void, { id: string, userId: string }>({
      async queryFn({ id, userId }) {
        const batch = writeBatch(DB);
        try {
          // Delete the contact
          const contactRef = doc(DB, 'contacts', id);
          batch.delete(contactRef);

          // Decrement user's contact count
          const userRef = doc(DB, 'users', userId);
          batch.update(userRef, { contactCount: increment(-1) });

          // Remove contact from all segments
          const segmentsRef = collection(DB, 'segments');
          const segmentsSnapshot = await getDocs(query(segmentsRef, where('userId', '==', userId)));
          
          segmentsSnapshot.forEach((segmentDoc) => {
            const contactSegmentRef = doc(DB, 'segments', segmentDoc.id, 'ContactSegments', id);
            batch.delete(contactSegmentRef);
          });

          await batch.commit();
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['Contacts', 'Segments'],
    }),

    // Segments
    getSegments: builder.query<Segment[], string>({
      async queryFn(userId) {
        try {
          const segmentsRef = collection(DB, 'segments');
          const q = query(segmentsRef, where('userId', '==', userId));
          const querySnapshot = await getDocs(q);
          const segments: Segment[] = [];
          querySnapshot.forEach((doc) => {
            segments.push({ id: doc.id, ...doc.data() } as Segment);
          });
          return { data: segments };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      providesTags: ['Segments'],
    }),

    addSegment: builder.mutation<Segment, Omit<Segment, 'id'>>({
      async queryFn(segment) {
        try {
          const docRef = await addDoc(collection(DB, 'segments'), segment);
          const newSegment: Segment = { id: docRef.id, ...segment };
          return { data: newSegment };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['Segments'],
    }),

    updateSegment: builder.mutation<void, Segment>({
      async queryFn(segment) {
        try {
          const { id, ...updateData } = segment;
          await updateDoc(doc(DB, 'segments', id), updateData);
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['Segments'],
    }),

    deleteSegment: builder.mutation<void, string>({
      async queryFn(id) {
        const batch = writeBatch(DB);
        try {
          const segmentRef = doc(DB, 'segments', id);
          batch.delete(segmentRef);

          // Delete all ContactSegments subcollection documents
          const contactSegmentsRef = collection(DB, 'segments', id, 'ContactSegments');
          const contactSegmentsSnapshot = await getDocs(contactSegmentsRef);
          contactSegmentsSnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });

          await batch.commit();
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['Segments'],
    }),

    // Get contacts for a specific segment
    getContactsForSegment: builder.query<Contact[], string>({
      async queryFn(segmentId) {
        try {
          const contactSegmentsRef = collection(DB, 'segments', segmentId, 'ContactSegments');
          const querySnapshot = await getDocs(contactSegmentsRef);
          
          const contactIds = querySnapshot.docs.map(doc => doc.data().contactId);
          
          const contacts: Contact[] = [];
          for (const contactId of contactIds) {
            const contactDoc = await getDoc(doc(DB, 'contacts', contactId));
            if (contactDoc.exists()) {
              contacts.push({ id: contactDoc.id, ...contactDoc.data() } as Contact);
            }
          }
          
          return { data: contacts };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      providesTags: ['Contacts', 'Segments'],
    }),

    // Add contact to segment
    addContactToSegment: builder.mutation<void, { segmentId: string, contactId: string }>({
      async queryFn({ segmentId, contactId }) {
        try {
          const contactSegmentRef = doc(DB, 'segments', segmentId, 'ContactSegments', contactId);
          await setDoc(contactSegmentRef, { contactId });
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['Segments'],
    }),

    // Remove contact from segment
    removeContactFromSegment: builder.mutation<void, { segmentId: string, contactId: string }>({
      async queryFn({ segmentId, contactId }) {
        try {
          const contactSegmentRef = doc(DB, 'segments', segmentId, 'ContactSegments', contactId);
          await deleteDoc(contactSegmentRef);
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['Segments'],
    }),
    importContacts: builder.mutation<void, File>({
        async queryFn(file) {
          try {
            const text = await file.text();
            const { data, meta } = Papa.parse(text, { header: true });
            
            const batch = writeBatch(DB);
            const contactsRef = collection(DB, 'contacts');
  
            const headers = meta.fields || [];
            const propertyMap = headers.map(mapHeaderToProperty);
  
            for (const row of data) {
              if (typeof row === 'object' && row !== null) {
                const contact: Partial<Contact> = {
                  userId: '', // Set this to the current user's ID
                };
  
                propertyMap.forEach((prop, index) => {
                  if (prop) {
                    const headerKey = headers[index];
                    const value = (row as Record<string, unknown>)[headerKey];
                    if (typeof value === 'string' && value !== '') {
                      contact[prop] = convertValue(value, prop);
                    }
                  }
                });
  
                // Ensure required fields are present
                if (contact.email) {
                  const docRef = doc(contactsRef);
                  batch.set(docRef, contact);
                }
              }
            }
  
            await batch.commit();
  
            return { data: undefined };
          } catch (error: any) {
            return { error: error.message };
          }
        },
        invalidatesTags: ['Contacts'],
      }),
      createOrUpdateDynamicSegment: builder.mutation<Segment, Partial<Segment>>({
        async queryFn(segmentData) {
          try {
            const { id, ...segmentFields } = segmentData;
            
            // Ensure required fields are present
            if (!segmentFields.userId || !segmentFields.name) {
              throw new Error('userId and name are required fields');
            }
  
            let segmentRef;
            let newSegment: Segment;
  
            if (id) {
              // Update existing segment
              segmentRef = doc(DB, 'segments', id);
              await updateDoc(segmentRef, {
                ...segmentFields,
                type: 'dynamic',
                updatedAt: new Date().toISOString(),
              });
              const updatedDoc = await getDoc(segmentRef);
              newSegment = { id, ...updatedDoc.data() } as Segment;
            } else {
              // Create new segment
              segmentRef = doc(collection(DB, 'segments'));
              newSegment = {
                id: segmentRef.id,
                ...segmentFields,
                type: 'dynamic',
                createdAt: new Date().toISOString(),
                contactCount: 0, // Initialize with 0, will be updated after filter application
              } as Segment;
              await setDoc(segmentRef, newSegment);
            }
  
            // Apply filters and update contact count
            if (newSegment.filters) {
              const contactsRef = collection(DB, 'contacts');
              let q = query(contactsRef, where('userId', '==', newSegment.userId));
  
              // Apply each filter
              Object.entries(newSegment.filters).forEach(([key, value]) => {
                if (value !== undefined && value !== null) {
                  q = query(q, where(key, '==', value));
                }
              });
  
              const querySnapshot = await getDocs(q);
              const contactCount = querySnapshot.size;
  
              // Update segment with new contact count
              await updateDoc(segmentRef, { contactCount });
              newSegment.contactCount = contactCount;
            }
  
            return { data: newSegment };
          } catch (error: any) {
            return { error: error.message };
          }
        },
        invalidatesTags: ['Segments'],
      }),
  
      // Modified function for contacts.api.ts
      createSegmentWithContacts: builder.mutation<Segment, { segment: Omit<Segment, 'id'>, contacts: Omit<Contact, 'id'>[] }>({
        async queryFn({ segment, contacts }) {
          try {
            // Create new segment first
            const segmentsRef = collection(DB, 'segments');
            const newSegmentRef = doc(segmentsRef);
            const newSegment: Segment = { 
              ...segment, 
              id: newSegmentRef.id,
              createdAt: new Date().toISOString(),
              contactCount: 0 // Initialize contact count
            };
            
            // Set the segment document directly (not in a batch)
            await setDoc(newSegmentRef, newSegment);
            
            // Process contacts in chunks to avoid batch size limits
            const BATCH_SIZE = 450; // Firestore limit is 500, using 450 to be safe
            let processedCount = 0;
            let successCount = 0;
            
            // Log progress for debugging
            console.log(`Starting to process ${contacts.length} contacts in batches of ${BATCH_SIZE}`);
            
            // Process in chunks
            for (let i = 0; i < contacts.length; i += BATCH_SIZE) {
              const batch = writeBatch(DB);
              const chunk = contacts.slice(i, i + BATCH_SIZE);
              console.log(`Processing batch ${Math.floor(i/BATCH_SIZE) + 1}, contacts ${i+1}-${Math.min(i+BATCH_SIZE, contacts.length)}`);
              
              for (const contact of chunk) {
                if (!contact.email || !contact.userId) {
                  console.warn("Skipping contact - missing email or userId", contact);
                  continue;
                }
                
                try {
                  const normalizedEmail = contact.email.trim().toLowerCase();
                  const contactId = generateContactId(normalizedEmail);
                  const contactRef = doc(DB, 'contacts', contactId);
                  
                  // Check if contact already exists
                  const existingContact = await getDoc(contactRef);
                  
                  let finalContactData: Contact;
                  
                  if (existingContact.exists()) {
                    // Merge existing contact with new data
                    finalContactData = { 
                      ...existingContact.data() as Contact, 
                      ...contact, 
                      id: contactId, 
                      email: normalizedEmail 
                    };
                    batch.set(contactRef, finalContactData, { merge: true });
                  } else {
                    // Insert new contact
                    finalContactData = { 
                      ...contact, 
                      id: contactId, 
                      userId: contact.userId,
                      email: normalizedEmail 
                    };
                    batch.set(contactRef, finalContactData);
                    
                    // Increment user's contact count only for new contacts
                    const userRef = doc(DB, 'users', contact.userId);
                    batch.update(userRef, { contactCount: increment(1) });
                  }
                  
                  // Create ContactSegment entry
                  const contactSegmentRef = doc(collection(DB, 'segments', newSegment.id, 'ContactSegments'), contactId);
                  const contactSegment: ContactSegment = {
                    id: contactId,
                    contactId: contactId,
                  };
                  batch.set(contactSegmentRef, contactSegment);
                  
                  // Track successful processing
                  successCount++;
                } catch (error) {
                  console.error(`Error processing contact in batch:`, error, contact);
                }
                
                processedCount++;
              }
              
              // Commit the current batch
              try {
                await batch.commit();
                console.log(`Successfully committed batch ${Math.floor(i/BATCH_SIZE) + 1}`);
              } catch (batchError) {
                console.error(`Error committing batch ${Math.floor(i/BATCH_SIZE) + 1}:`, batchError);
              }
            }
            
            // Update the segment with the final contact count
            await updateDoc(newSegmentRef, { contactCount: successCount });
            newSegment.contactCount = successCount;
            
            console.log(`Import complete: ${successCount} contacts added to segment out of ${contacts.length} total`);
            
            return { data: newSegment };
          } catch (error: any) {
            console.error("Failed to create segment with contacts:", error);
            return { error: error.message };
          }
        },
        invalidatesTags: ['Segments', 'Contacts'],
      }),
      getGuestLists: builder.query<GuestList[], string>({
        async queryFn(eventId) {
          try {
            const eventRef = doc(DB, 'events', eventId);
            const eventSnap = await getDoc(eventRef);
            if (eventSnap.exists()) {
              const eventData = eventSnap.data();
              return { data: eventData.guestLists || [] };
            } else {
              return { error: 'Event not found' };
            }
          } catch (error: any) {
            return { error: error.message };
          }
        },
        providesTags: ['GuestLists'],
      }),
  
      addGuestList: builder.mutation<void, { eventId: string; guestList: Omit<GuestList, 'id'> }>({
        async queryFn({ eventId, guestList }) {
          try {
            const eventRef = doc(DB, 'events', eventId);
            const newList: GuestList = { id: Date.now().toString(), ...guestList };
            await updateDoc(eventRef, {
              guestLists: arrayUnion(newList)
            });
            return { data: undefined };
          } catch (error: any) {
            return { error: error.message };
          }
        },
        invalidatesTags: ['GuestLists'],
      }),
  
      removeGuestList: builder.mutation<void, { eventId: string; guestListId: string }>({
        async queryFn({ eventId, guestListId }) {
          try {
            const eventRef = doc(DB, 'events', eventId);
            const eventSnap = await getDoc(eventRef);
            if (eventSnap.exists()) {
              const eventData = eventSnap.data();
              const updatedGuestLists = eventData.guestLists.filter((list: GuestList) => list.id !== guestListId);
              await updateDoc(eventRef, { guestLists: updatedGuestLists });
              return { data: undefined };
            } else {
              return { error: 'Event not found' };
            }
          } catch (error: any) {
            return { error: error.message };
          }
        },
        invalidatesTags: ['GuestLists'],
      }),
  
      getGuests: builder.query<Guest[], { eventId: string; listId: string }>({
        async queryFn({ eventId, listId }) {
          try {
            const guestsRef = collection(DB, 'events', eventId, 'guests');
            const q = query(guestsRef, where('listId', '==', listId));
            const querySnapshot = await getDocs(q);
            const guests: Guest[] = [];
            querySnapshot.forEach((doc) => {
              guests.push({ id: doc.id, ...doc.data() } as Guest);
            });
            return { data: guests };
          } catch (error: any) {
            return { error: error.message };
          }
        },
        providesTags: ['Guests'],
      }),
  
  
      updateGuest: builder.mutation<void, { eventId: string, guestId: string, guest: Partial<Guest> }>({
        async queryFn({ eventId, guestId, guest }) {
          try {
            try {
              const guestRef = doc(DB, 'events', eventId, 'guests', guestId);
              await updateDoc(guestRef, guest);
              return { data: undefined };
            } catch (error: any) {
              return { error: error.message };
            }
          } catch (error: any) {
            return { error: error.message };
          }
        },
        invalidatesTags: ['Guests'],
      }),
  
      removeGuest: builder.mutation<void, { eventId: string; guestId: string }>({
        async queryFn({ eventId, guestId }) {
          try {
            const guestRef = doc(DB, 'events', eventId, 'guests', guestId);
            await deleteDoc(guestRef);
            return { data: undefined };
          } catch (error: any) {
            return { error: error.message };
          }
        },
        invalidatesTags: ['Guests'],
      }),
  
      addMultipleGuests: builder.mutation<void, { eventId: string; guests: Omit<Guest, 'id'>[]; sendEmails: boolean }>({
        async queryFn({ eventId, guests, sendEmails }) {
          const batch = writeBatch(DB);
          try {
            const guestRef = collection(DB, 'events', eventId, 'guests');
            guests.forEach((guest) => {
              const newDocRef = doc(guestRef);
              batch.set(newDocRef, { ...guest, sendInvitationEmail: sendEmails });
            });
            await batch.commit();     
            
      
            return { data: undefined };
          } catch (error: any) {
            return { error: error.message };
          }
        },
        invalidatesTags: ['Guests'],
      }),
      
      addGuest: builder.mutation<void, { eventId: string; guest: Omit<Guest, 'id'>; sendEmail: boolean }>({
        async queryFn({ eventId, guest, sendEmail }) {
          try {
            const guestRef = collection(DB, 'events', eventId, 'guests');
            const docRef = await addDoc(guestRef, { ...guest, sendInvitationEmail: sendEmail });
      
            return { data: undefined };
          } catch (error: any) {
            return { error: error.message };
          }
        },
        invalidatesTags: ['Guests'],
      }),
  }),
});

export const {
  useGetContactsQuery,
  useAddContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useGetSegmentsQuery,
  useAddSegmentMutation,
  useUpdateSegmentMutation,
  useDeleteSegmentMutation,
  useGetContactsForSegmentQuery,
  useAddContactToSegmentMutation,
  useImportContactsMutation,
  useCreateSegmentWithContactsMutation,
  useRemoveContactFromSegmentMutation,
  useGetGuestListsQuery,
  useAddGuestListMutation,
  useRemoveGuestListMutation,
  useGetGuestsQuery,
  useAddGuestMutation,
  useUpdateGuestMutation,
  useRemoveGuestMutation,
  useAddMultipleGuestsMutation,
  useCreateOrUpdateDynamicSegmentMutation
} = ContactsApi;