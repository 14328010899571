import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  limit,
  orderBy,
  startAfter,
  Timestamp,
  getCountFromServer,
  writeBatch,
  serverTimestamp,
  updateDoc,
  deleteField
} from 'firebase/firestore';
import { format } from 'date-fns';
import { DB } from 'src/contexts/FirebaseContext';
import { MailCampaign, MailItem, CampaignLog } from 'src/@types/mailcampaign';

// Enhanced mail record interface
export interface EnhancedMailItem extends MailItem {
    id: string;
  sentAt?: Timestamp;
  errorAt?: Timestamp;
  status: 'sent' | 'failed';
  error?: string;
}

// Define the Mail Campaign API
export const MailCampaignApi = createApi({
  reducerPath: 'MailCampaignApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: [
    'MailCampaign',
    'MailCampaignDetails',
    'MailCampaignMails',
    'MailCampaignLogs',
    'MailCampaignStats'
  ],
  endpoints: (builder) => ({
    getMailCampaignDetails: builder.query<
      { campaign: MailCampaign; totalMails: number; totalLogs: number },
      { eventId: string; campaignId: string }
    >({
      async queryFn({ eventId, campaignId }) {
        try {
          // Get the campaign document
          const campaignRef = doc(DB, 'events', eventId, 'mailcampaigns', campaignId);
          const campaignDoc = await getDoc(campaignRef);
          
          if (!campaignDoc.exists()) {
            return { error: 'Campaign not found' };
          }
          
          const campaign = {
            id: campaignDoc.id,
            ...campaignDoc.data()
          } as MailCampaign;
          
          // Get the total count of mails for pagination
          const mailsQuery = collection(DB, 'events', eventId, 'mailcampaigns', campaignId, 'mails');
          const mailsSnapshot = await getCountFromServer(mailsQuery);
          const totalMails = mailsSnapshot.data().count;
          console.log(totalMails);
          
          // Get the total count of logs for pagination
          const logsQuery = collection(DB, 'events', eventId, 'mailcampaigns', campaignId, 'logs');
          const logsSnapshot = await getCountFromServer(logsQuery);
          const totalLogs = logsSnapshot.data().count;
          
          return { 
            data: { 
              campaign, 
              totalMails,
              totalLogs
            } 
          };
        } catch (error: any) {
          console.error('Error fetching campaign details:', error);
          return { error: error.message };
        }
      },
      providesTags: (result, error, { campaignId }) => [
        { type: 'MailCampaign', id: campaignId },
        { type: 'MailCampaignDetails', id: campaignId }
      ],
    }),

    // Enhanced version supporting the new mail subcollection structure
    // Enhanced version supporting the new mail subcollection structure
    getMailCampaignMails: builder.query<
    { mails: EnhancedMailItem[]; hasMore: boolean; lastId: string | null },
    { eventId: string; campaignId: string; limit?: number; startAfterId?: string | null; status?: string }
    >({
    async queryFn(arg, _api, _extraOptions, _baseQuery) {
    const { eventId, campaignId, limit: pageLimit = 50, startAfterId = null, status } = arg;
    
    try {
        const mailsRef = collection(DB, 'events', eventId, 'mailcampaigns', campaignId, 'mails');
        
        // Create query constraints
        const queryConstraints = [];
        
        // Add ordering (by createdAt for the new structure)
        queryConstraints.push(orderBy('createdAt', 'desc'));
        
        // Add status filter if provided
        if (status) {
        queryConstraints.push(where('status', '==', status));
        }
        
        // Add pagination limits (+1 to check if there are more)
        queryConstraints.push(limit(pageLimit + 1));
        
        // Add startAfter if provided
        if (startAfterId) {
        const startAfterDoc = await getDoc(doc(mailsRef, startAfterId));
        if (startAfterDoc.exists()) {
            queryConstraints.push(startAfter(startAfterDoc));
        }
        }
        
        // Execute final query
        const mailsQuery = query(mailsRef, ...queryConstraints);
        const mailsSnapshot = await getDocs(mailsQuery);
        
        // Check if there are more items
        const hasMore = mailsSnapshot.docs.length > pageLimit;
        
        // Get only the requested number of documents
        const mailDocs = hasMore ? mailsSnapshot.docs.slice(0, pageLimit) : mailsSnapshot.docs;
        
        // Map the mail items
        const mails = mailDocs.map(doc => {
        const data = doc.data();
        return {
            id: doc.id,
            ...data,
            // Ensure timestamps are properly handled
            ...(data.createdAt && { createdAt: data.createdAt }),
            ...(data.sentAt && { sentAt: data.sentAt }),
            ...(data.errorAt && { errorAt: data.errorAt }),
        } as EnhancedMailItem;
        });
        
        // Get the ID of the last document for pagination
        const lastId = mails.length > 0 ? mails[mails.length - 1].id : null;
        
        return { data: { mails, hasMore, lastId } };
    } catch (error: any) {
        console.error('Error fetching campaign mails:', error);
        return { error: error.message };
    }
    },
    providesTags: (result, error, { campaignId }) => [
    { type: 'MailCampaignMails', id: campaignId }
    ],
    }),

    getMailCampaignLogs: builder.query<
      { logs: CampaignLog[]; hasMore: boolean },
      { eventId: string; campaignId: string; page: number; limit: number }
    >({
      async queryFn({ eventId, campaignId, page, limit: pageLimit }) {
        try {
          const logsRef = collection(DB, 'events', eventId, 'mailcampaigns', campaignId, 'logs');
          
          // Create query constraints
          const queryConstraints = [];
          
          // Add ordering
          queryConstraints.push(orderBy('timestamp', 'desc'));
          
          // Add pagination limits
          queryConstraints.push(limit(pageLimit));
          
          // If not the first page, add startAfter cursor
          if (page > 0) {
            // We need to get a reference to the last document of the previous page
            const prevPageQuery = query(
              logsRef,
              orderBy('timestamp', 'desc'),
              limit(page * pageLimit)
            );
            
            const prevPageSnapshot = await getDocs(prevPageQuery);
            const lastVisibleDoc = prevPageSnapshot.docs[prevPageSnapshot.docs.length - 1];
            
            if (lastVisibleDoc) {
              queryConstraints.push(startAfter(lastVisibleDoc));
            }
          }
          
          // Execute final query
          const logsQuery = query(logsRef, ...queryConstraints);
          const logsSnapshot = await getDocs(logsQuery);
          
          // Check if there are more items
          const hasMore = logsSnapshot.docs.length === pageLimit;
          
          // Map the log items
          const logs = logsSnapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              // Ensure timestamp is properly handled
              ...(data.timestamp && {
                timestamp: data.timestamp,
              }),
            } as CampaignLog;
          });
          
          return { data: { logs, hasMore } };
        } catch (error: any) {
          console.error('Error fetching campaign logs:', error);
          return { error: error.message };
        }
      },
      providesTags: (result, error, { campaignId, page }) => [
        { type: 'MailCampaignLogs', id: `${campaignId}-page-${page}` }
      ],
    }),

    // Enhanced version of getMailCampaignStats for the new structure
    getMailCampaignStats: builder.query<
      {
        stats: {
          totalProcessed: number;
          totalSuccess: number;
          totalFailed: number;
          totalSkipped: number;
          mailCount: number;
          sentCount: number;
          failedCount: number;
          deliveryRate: string;
          failureRate: string;
          byDay?: Array<{ date: string; sent: number; failed: number }>;
        }
      },
      { eventId: string; campaignId: string }
    >({
      async queryFn({ eventId, campaignId }) {
        try {
          // Get the campaign document for overall stats
          const campaignRef = doc(DB, 'events', eventId, 'mailcampaigns', campaignId);
          const campaignDoc = await getDoc(campaignRef);
          
          if (!campaignDoc.exists()) {
            return { error: 'Campaign not found' };
          }
          
          const campaignData = campaignDoc.data();
          
          // Get mail statistics from subcollection
          const mailsRef = collection(campaignRef, 'mails');
          
          // Get sent count
          const sentCountQuery = query(mailsRef, where('status', '==', 'sent'));
          const sentSnapshot = await getCountFromServer(sentCountQuery);
          const sentCount = sentSnapshot.data().count;
          
          // Get failed count
          const failedCountQuery = query(mailsRef, where('status', '==', 'failed'));
          const failedSnapshot = await getCountFromServer(failedCountQuery);
          const failedCount = failedSnapshot.data().count;
          
          // Get total count
          const totalMailsSnapshot = await getCountFromServer(mailsRef);
          const totalMailCount = totalMailsSnapshot.data().count;
          
          // Calculate rates
          const deliveryRate = totalMailCount > 0 ? (sentCount / totalMailCount * 100).toFixed(2) : '0';
          const failureRate = totalMailCount > 0 ? (failedCount / totalMailCount * 100).toFixed(2) : '0';
          
          // Get daily stats (limited to last 500 emails for performance)
          const recentMailsQuery = query(
            mailsRef,
            orderBy('createdAt', 'desc'),
            limit(500)
          );
          
          const recentMailsSnapshot = await getDocs(recentMailsQuery);
          const dailyStats: { [date: string]: { sent: number; failed: number } } = {};
          
          // Process each mail item for daily stats
          recentMailsSnapshot.docs.forEach(doc => {
            const mailData = doc.data();
            
            // Process dates for timeline (use sentAt first, fall back to createdAt)
            const timestamp = mailData.sentAt || mailData.createdAt;
            if (timestamp instanceof Timestamp) {
              const date = format(timestamp.toDate(), 'yyyy-MM-dd');
              
              if (!dailyStats[date]) {
                dailyStats[date] = { sent: 0, failed: 0 };
              }
              
              // Count events based on status
              if (mailData.status === 'sent') {
                dailyStats[date].sent += 1;
              } else if (mailData.status === 'failed') {
                dailyStats[date].failed += 1;
              }
            }
          });
          
          // Convert daily stats to array for charting
          const byDay = Object.entries(dailyStats).map(([date, counts]) => ({
            date,
            ...counts
          })).sort((a, b) => a.date.localeCompare(b.date));
          
          return { 
            data: { 
              stats: {
                // From campaign document
                totalProcessed: campaignData.totalProcessed || 0,
                totalSuccess: campaignData.totalSuccess || 0,
                totalFailed: campaignData.totalFailed || 0,
                totalSkipped: campaignData.totalSkipped || 0,
                
                // From mails subcollection
                mailCount: totalMailCount,
                sentCount,
                failedCount,
                
                // Calculated rates
                deliveryRate,
                failureRate,
                
                // Daily stats for charts
                byDay
              }
            } 
          };
        } catch (error: any) {
          console.error('Error fetching campaign stats:', error);
          return { error: error.message };
        }
      },
      providesTags: (result, error, { campaignId }) => [
        { type: 'MailCampaignStats', id: campaignId }
      ],
    }),

  }),
});

// Export hooks for usage in functional components
export const {
  useGetMailCampaignDetailsQuery,
  useGetMailCampaignMailsQuery,
  useGetMailCampaignLogsQuery,
  useGetMailCampaignStatsQuery,
} = MailCampaignApi;