// src/redux/api/emailTemplates.api.ts
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';

export interface EmailTemplate {
  id: string;
  name: string;
  description: string;
  type: string;
  subject: Record<string, string>;
  html: Record<string, string>;
  placeholders: Array<{
    key: string;
    description: string;
    required: boolean;
  }>;
  settings: {
    from: {
      name: string;
      email: string;
    };
    replyTo: {
      name: string;
      email: string;
    };
  };
  status: string;
  metadata: {
    version: number;
    useCount: number;
    createdAt: string;
    updatedAt: string;
  };
}

export const emailTemplatesApi = createApi({
  reducerPath: 'emailTemplatesApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['EmailTemplate'],
  endpoints: (builder) => ({
    fetchEmailTemplate: builder.query<EmailTemplate, string>({
      queryFn: async (templateId) => {
        try {
          const templateRef = doc(DB, 'emailTemplates', templateId);
          const templateSnap = await getDoc(templateRef);
          
          if (templateSnap.exists()) {
            return { data: { id: templateSnap.id, ...templateSnap.data() } as EmailTemplate };
          } else {
            return { error: { status: 404, data: 'Template not found' } };
          }
        } catch (error) {
          return { error: { status: 500, data: error } };
        }
      },
      providesTags: (result, error, id) => [{ type: 'EmailTemplate', id }],
    }),
    
    fetchEmailTemplateByType: builder.query<EmailTemplate, string>({
      queryFn: async (templateType) => {
        try {
          const templatesRef = collection(DB, 'emailTemplates');
          const q = query(templatesRef, where('type', '==', templateType));
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            const templateDoc = querySnapshot.docs[0];
            return { data: { id: templateDoc.id, ...templateDoc.data() } as EmailTemplate };
          } else {
            return { error: { status: 404, data: 'No template found with this type' } };
          }
        } catch (error) {
          return { error: { status: 500, data: error } };
        }
      },
      providesTags: (result, error, type) => [{ type: 'EmailTemplate', id: type }],
    }),
  }),
});

export const { 
  useFetchEmailTemplateQuery,
  useFetchEmailTemplateByTypeQuery,
} = emailTemplatesApi;