// src/redux/api/busTrips.api.ts

import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { 
  collection, 
  getDocs, 
  addDoc, 
  updateDoc, 
  deleteDoc,
  doc,
  Timestamp,
  query,
  where,
  getDoc
} from 'firebase/firestore';
import { DB, FUNCTIONS } from 'src/contexts/FirebaseContext';
import { 
  BusTrip, 
  BusSchedule,
  CreateBusTripRequest, 
  UpdateBusTripRequest, 
  DeleteBusTripRequest,
  UpdateScheduleRequest,
  BusTripResponse 
} from 'src/@types/bus';

export const BusTripsApi = createApi({
  reducerPath: 'busTripsApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['BusTrips'],
  endpoints: (builder) => ({
    fetchBusTrips: builder.query<BusTrip[], string>({
      queryFn: async (eventId) => {
        try {
          const tripsRef = collection(DB, 'events', eventId, 'busTrips');
          const snapshot = await getDocs(tripsRef);
          
          // Map documents to BusTrip objects with proper direction info
          const trips = snapshot.docs.map(doc => {
            const data = doc.data();
            
            // Ensure schedule items have direction property
            const schedule = data.schedule || [];
            const updatedSchedule = schedule.map((item: any) => {
              // Add direction property if missing (for backward compatibility)
              if (!item.direction) {
                // Determine direction from locations (fallback logic)
                const isDepartureFromChateau = item.departureLocation?.includes('Château');
                item.direction = isDepartureFromChateau ? 'aller' : 'retour';
                
                // Add trip number if missing
                if (!item.tripNumber) {
                  // Generate a unique trip number within direction
                  const directionItems = schedule.filter((s: any) => 
                    (s.direction === item.direction) || 
                    (!s.direction && 
                      ((isDepartureFromChateau && s.departureLocation?.includes('Château')) ||
                       (!isDepartureFromChateau && !s.departureLocation?.includes('Château')))
                    )
                  );
                  item.tripNumber = directionItems.length + 1;
                }
              }
              
              return item;
            });
            
            // Ensure metadata has passengersByDirection
            let metadata = data.metadata || {};
            if (!metadata.passengersByDirection) {
              // Calculate passenger counts by direction
              const passengersByDirection = {
                aller: 0,
                retour: {} as Record<string, number>
              };
              
              // Count passengers by direction
              updatedSchedule.forEach((item: any) => {
                if (item.direction === 'aller' && item.passengerCount) {
                  passengersByDirection.aller += item.passengerCount;
                } else if (item.direction === 'retour' && item.passengerCount) {
                  const location = item.departureLocation || 'Unknown';
                  passengersByDirection.retour[location] = 
                    (passengersByDirection.retour[location] || 0) + item.passengerCount;
                }
              });
              
              metadata = {
                ...metadata,
                passengersByDirection,
                totalPassengers: updatedSchedule.reduce((sum: number, item: any) => 
                  sum + (item.passengerCount || 0), 0)
              };
            }
            
            return {
              id: doc.id,
              ...data,
              schedule: updatedSchedule,
              metadata,
              // Ensure isRoundTrip is true for trips with both aller and retour schedules
              isRoundTrip: data.isRoundTrip !== undefined ? data.isRoundTrip : 
                (updatedSchedule.some((s: any) => s.direction === 'aller') && 
                 updatedSchedule.some((s: any) => s.direction === 'retour'))
            } as BusTrip;
          });

          return { data: trips };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      providesTags: ['BusTrips']
    }),

    fetchBusTrip: builder.query<BusTrip, { eventId: string; tripId: string }>({
      queryFn: async ({ eventId, tripId }) => {
        try {
          const tripRef = doc(DB, 'events', eventId, 'busTrips', tripId);
          const snapshot = await getDoc(tripRef);
          
          if (!snapshot.exists()) {
            throw new Error('Bus trip not found');
          }

          const data = snapshot.data();
          
          // Add direction and trip numbers if missing
          const schedule = (data.schedule || []).map((item: any, index: number) => {
            if (!item.direction) {
              const isDepartureFromChateau = item.departureLocation?.includes('Château');
              item.direction = isDepartureFromChateau ? 'aller' : 'retour';
              
              // Track existing trip numbers by direction
              const existingTripNumbers = (data.schedule || [])
                .filter((s: any) => s.direction === item.direction && s.tripNumber)
                .map((s: any) => s.tripNumber);
              
              // Generate a unique trip number
              if (!item.tripNumber) {
                item.tripNumber = existingTripNumbers.length > 0 ? 
                  Math.max(...existingTripNumbers) + 1 : 1;
              }
            }
            return item;
          });
          
          // Ensure metadata has passengersByDirection
          let metadata = data.metadata || {};
          if (!metadata.passengersByDirection) {
            // Calculate passenger counts by direction
            const passengersByDirection = {
              aller: 0,
              retour: {} as Record<string, number>
            };
            
            // Count passengers by direction
            schedule.forEach((item: any) => {
              if (item.direction === 'aller' && item.passengerCount) {
                passengersByDirection.aller += item.passengerCount;
              } else if (item.direction === 'retour' && item.passengerCount) {
                const location = item.departureLocation || 'Unknown';
                passengersByDirection.retour[location] = 
                  (passengersByDirection.retour[location] || 0) + item.passengerCount;
              }
            });
            
            metadata = {
              ...metadata,
              passengersByDirection,
              totalPassengers: schedule.reduce((sum: number, item: any) => 
                sum + (item.passengerCount || 0), 0)
            };
          }

          const trip = {
            id: snapshot.id,
            ...data,
            schedule,
            metadata,
            // Ensure isRoundTrip is true for trips with both aller and retour schedules
            isRoundTrip: data.isRoundTrip !== undefined ? data.isRoundTrip : 
              (schedule.some((s: any) => s.direction === 'aller') && 
               schedule.some((s: any) => s.direction === 'retour'))
          } as BusTrip;

          return { data: trip };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      providesTags: (result, error, arg) => 
        result ? [{ type: 'BusTrips', id: arg.tripId }] : ['BusTrips']
    }),

    createBusTrip: builder.mutation<BusTripResponse, CreateBusTripRequest>({
      queryFn: async ({ eventId, busTrip }) => {
        try {
          const tripsRef = collection(DB, 'events', eventId, 'busTrips');
          
          // Ensure schedule items have direction and trip numbers
          const schedule = (busTrip.schedule || []).map((item) => {
            if (!item.direction) {
              const isDepartureFromChateau = item.departureLocation?.includes('Château');
              item.direction = isDepartureFromChateau ? 'aller' : 'retour';
            }
            
            if (!item.tripNumber) {
              // Get existing trip numbers for this direction
              const existingTripNumbers = busTrip.schedule
                ?.filter(s => s.direction === item.direction && s.tripNumber)
                .map(s => s.tripNumber) || [];
              
              // Assign a new trip number
              item.tripNumber = existingTripNumbers.length > 0 ? 
                Math.max(...existingTripNumbers) + 1 : 1;
            }
            
            return item;
          });
          
          // Calculate passenger counts by direction
          let passengersByDirection = busTrip.metadata?.passengersByDirection;
          if (!passengersByDirection) {
            passengersByDirection = {
              aller: 0,
              retour: {} as Record<string, number>
            };
            
            // Count passengers by direction
            schedule.forEach((item) => {
              if (item.direction === 'aller' && item.passengerCount) {
                passengersByDirection!.aller += item.passengerCount;
              } else if (item.direction === 'retour' && item.passengerCount) {
                const location = item.departureLocation || 'Unknown';
                passengersByDirection!.retour[location] = 
                  (passengersByDirection!.retour[location] || 0) + item.passengerCount!;
              }
            });
          }
          
          // Calculate total passengers
          const totalPassengers = schedule.reduce((sum, item) => 
            sum + (item.passengerCount || 0), 0);
          
          // Prepare metadata
          const metadata = {
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            createdBy: busTrip.metadata?.createdBy || null,
            updatedBy: busTrip.metadata?.updatedBy || null,
            passengersByDirection,
            totalPassengers
          };
          
          // Create final trip data
          const newTripData = {
            ...busTrip,
            schedule,
            metadata,
            // Set isRoundTrip based on schedule if not explicitly set
            isRoundTrip: busTrip.isRoundTrip !== undefined ? busTrip.isRoundTrip : 
              (schedule.some(s => s.direction === 'aller') && 
               schedule.some(s => s.direction === 'retour'))
          };
    
          const docRef = await addDoc(tripsRef, newTripData);
          
          // Create the trip object with ID for the response
          const tripWithId = {
            id: docRef.id,
            ...newTripData
          } as BusTrip;
          
          // Return exact BusTripResponse type
          return { 
            data: { 
              success: true, 
              data: tripWithId
            } 
          };
        } catch (error: any) {
          // Return exact BusTripResponse type for error
          return { 
            error: error.message 
          };
        }
      },
      invalidatesTags: ['BusTrips']
    }),

    updateBusTrip: builder.mutation<BusTripResponse, UpdateBusTripRequest>({
      queryFn: async ({ eventId, tripId, busTrip }) => {
        try {
          const tripRef = doc(DB, 'events', eventId, 'busTrips', tripId);
          
          // Keep schedule unchanged, only update trip details
          const { schedule, ...updateData } = busTrip;
          
          const updatedData = {
            ...updateData,
            "metadata.updatedAt": Timestamp.now(),
            "metadata.updatedBy": busTrip.metadata?.updatedBy || null
          };

          await updateDoc(tripRef, updatedData);
          
          // Get the updated document to return in response
          const updatedDoc = await getDoc(tripRef);
          if (!updatedDoc.exists()) {
            throw new Error('Bus trip not found after update');
          }
          
          const updatedTrip = {
            id: tripId,
            ...updatedDoc.data()
          } as BusTrip;

          // Return exact BusTripResponse type
          return { 
            data: { 
              success: true,
              data: updatedTrip
            } 
          };
        } catch (error: any) {
          // Return exact BusTripResponse type for error
          return { 
            error: error.message
          };
        }
      },
      invalidatesTags: (result, error, arg) => 
        [{ type: 'BusTrips', id: arg.tripId }, 'BusTrips']
    }),

    updateBusTripSchedule: builder.mutation<BusTripResponse, UpdateScheduleRequest>({
      queryFn: async ({ eventId, tripId, schedule }) => {
        try {
          // Get the current trip to calculate updated metadata
          const tripRef = doc(DB, 'events', eventId, 'busTrips', tripId);
          const tripDoc = await getDoc(tripRef);
          
          if (!tripDoc.exists()) {
            throw new Error('Bus trip not found');
          }
          
          // Ensure all schedule items have direction and trip numbers
          const updatedSchedule = schedule.map((item) => {
            if (!item.direction) {
              const isDepartureFromChateau = item.departureLocation?.includes('Château');
              item.direction = isDepartureFromChateau ? 'aller' : 'retour';
            }
            
            if (!item.tripNumber) {
              // Get existing trip numbers for this direction
              const existingTripNumbers = schedule
                .filter(s => s.direction === item.direction && s.tripNumber)
                .map(s => s.tripNumber);
              
              // Assign a new trip number
              item.tripNumber = existingTripNumbers.length > 0 ? 
                Math.max(...existingTripNumbers) + 1 : 1;
            }
            
            return item;
          });
          
          // Calculate passenger counts by direction
          const passengersByDirection = {
            aller: 0,
            retour: {} as Record<string, number>
          };
          
          // Count passengers by direction
          updatedSchedule.forEach((item) => {
            if (item.direction === 'aller' && item.passengerCount) {
              passengersByDirection.aller += item.passengerCount;
            } else if (item.direction === 'retour' && item.passengerCount) {
              const location = item.departureLocation || 'Unknown';
              passengersByDirection.retour[location] = 
                (passengersByDirection.retour[location] || 0) + item.passengerCount!;
            }
          });
          
          // Calculate total passengers
          const totalPassengers = updatedSchedule.reduce((sum, item) => 
            sum + (item.passengerCount || 0), 0);
          
          // Update the trip with schedule and updated metadata
          await updateDoc(tripRef, {
            schedule: updatedSchedule,
            "metadata.updatedAt": Timestamp.now(),
            "metadata.passengersByDirection": passengersByDirection,
            "metadata.totalPassengers": totalPassengers,
            // Update isRoundTrip based on schedule
            isRoundTrip: updatedSchedule.some(s => s.direction === 'aller') && 
                         updatedSchedule.some(s => s.direction === 'retour')
          });
          
          // Get the updated trip for response
          const updatedTripDoc = await getDoc(tripRef);
          if (!updatedTripDoc.exists()) {
            throw new Error('Bus trip not found after schedule update');
          }
          
          const updatedTrip = {
            id: tripId,
            ...updatedTripDoc.data()
          } as BusTrip;

          // Return exact BusTripResponse type
          return { 
            data: { 
              success: true,
              data: updatedTrip
            } 
          };
        } catch (error: any) {
          // Return exact BusTripResponse type for error
          return { 
            error: error.message
          };
        }
      },
      invalidatesTags: (result, error, arg) => 
        [{ type: 'BusTrips', id: arg.tripId }, 'BusTrips']
    }),

    deleteBusTrip: builder.mutation<BusTripResponse, DeleteBusTripRequest>({
      queryFn: async ({ eventId, tripId }) => {
        try {
          await deleteDoc(doc(DB, 'events', eventId, 'busTrips', tripId));
          
          // Return exact BusTripResponse type
          return { 
            data: { 
              success: true 
            } 
          };
        } catch (error: any) {
          // Return exact BusTripResponse type for error
          return { 
            error: error.message
          };
        }
      },
      invalidatesTags: ['BusTrips']
    })
  })
});

export const {
  useFetchBusTripsQuery,
  useFetchBusTripQuery,
  useCreateBusTripMutation,
  useUpdateBusTripMutation,
  useUpdateBusTripScheduleMutation,
  useDeleteBusTripMutation
} = BusTripsApi;