import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { collection, collectionGroup, doc, Firestore, getDocs, query, runTransaction, serverTimestamp, where, writeBatch } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Attendee } from 'src/@types/attendee';
import { DB, FUNCTIONS } from 'src/contexts/FirebaseContext';

interface RefundRequest {
  amount: number;
  ticketid: string;
  eventid: string;
  reservationid: string;
  pi: string;
  userid: string;
}

interface RefundResponse {
  id: string;
  object: 'refund';
  status: string;
  // Add other Stripe refund response fields as needed
}

interface UpdateReservationUserInfo {
  reservationId: string;
  firstName: string;
  lastName: string;
  email: string;
  ticketId: string;  // Add this to know which ticket to update
  userId: string; 
  isReservationEdit: boolean;
}



export const ReservationsApi = createApi({
    reducerPath: 'ReservationsApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Attendees', 'Tickets', 'Reservations', 'Reservation'],
  endpoints: (builder) => ({
    getAttendees: builder.query<Attendee[], { eventId: string; status: string; outputFormat: string }>({
      async queryFn(args) {
        try {
          const fetchReservationsCallable = httpsCallable<
            { eventId: string; status: string; outputFormat: string },
            { attendees: Attendee[] }
          >(FUNCTIONS, 'reservations-fetchReservationsCallable');
          
          const result = await fetchReservationsCallable(args);
          return { data: result.data.attendees };
        } catch (error: any) {
          console.error(error);
          return { error: error.message };
        }
      },
      providesTags: ['Attendees'],
    }),
    getConfirmedTicketIds: builder.query<string[], string>({
      async queryFn(eventId) {
        try {
          const ticketsRef = collectionGroup(DB, 'tickets');
          const q = query(
            ticketsRef, 
            where('eventid', '==', eventId),
            where('status', '==', 'confirmed')
          );
          const querySnapshot = await getDocs(q);
          const ticketIds: string[] = [];
          querySnapshot.forEach((doc) => {
            ticketIds.push(doc.id);
          });
          return { data: ticketIds };
        } catch (error: any) {
          return { error: error.message };
        }
      },
    }),
    updateReservationUserInfo: builder.mutation({
      queryFn: async ({ 
        reservationId, 
        ticketId, 
        userId, 
        firstName, 
        lastName, 
        email,
        isReservationEdit = false
      }) => {
        try {
          const batch = writeBatch(DB);
          
          // Update ticket user info - always happens
          const ticketDocRef = doc(DB, "tickets", ticketId);
          
          batch.update(ticketDocRef, {
            "userinfos.firstName": firstName,
            "userinfos.lastName": lastName,
          });
          
          // Update the email appropriately based on the edit mode
          if (email) {
            if (isReservationEdit) {
              // In reservation edit mode, update the client email on the reservation
              const reservationDocRef = doc(DB, "reservations", reservationId);
              
              batch.update(reservationDocRef, {
                "client.email": email
              });
            } else {
              // In ticket edit mode, update just the ticket's email field
              batch.update(ticketDocRef, {
                "userinfos.email": email
              });
            }
          }
          
          // Add audit trail
          const auditLogsCollection = collection(DB, "audit_logs");
          const auditDocRef = doc(auditLogsCollection);
          
          batch.set(auditDocRef, {
            action: isReservationEdit ? "update_reservation" : "update_ticket",
            entityId: isReservationEdit ? reservationId : ticketId,
            entityType: isReservationEdit ? "reservation" : "ticket",
            changes: {
              firstName,
              lastName,
              email
            },
            timestamp: serverTimestamp(),
            userId
          });
          
          await batch.commit();
          
          return { data: { success: true } };
        } catch (error) {
          console.error("Error updating reservation info:", error);
          return { error: "Failed to update information" };
        }
      },
      invalidatesTags: (result, error, { reservationId }) => [
        { type: 'Reservation', id: reservationId }
      ]
    }),
    refundTicket: builder.mutation<RefundResponse, RefundRequest>({
      async queryFn(refundData) {
        try {
          const getStripeRefund = httpsCallable<RefundRequest, RefundResponse>(
            FUNCTIONS, 
            'getStripeRefund'
          );

          const response = await getStripeRefund({
            amount: refundData.amount,
            ticketid: refundData.ticketid,
            eventid: refundData.eventid,
            reservationid: refundData.reservationid,
            pi: refundData.pi,
            userid: refundData.userid,
          });
          console.log(response.data);

          return { data: response.data };
        } catch (error: any) {
          console.error(error.message);
          console.error(error.code);
          return {
            error: {
              status: error?.code || 'UNKNOWN_ERROR',
              data: error?.message || 'An unknown error occurred',
            },
          };
        }
      },
      // Invalidate relevant cache after refund
      invalidatesTags: ['Tickets', 'Reservations'],
    }),
    sendConfirmationEmail: builder.mutation<void, { mode: string, reservationid: string }>({
      async queryFn({ mode, reservationid }) {
        try {
          const createMailTask = httpsCallable(FUNCTIONS, 'createMailTask');
          await createMailTask({ mode, reservationid });
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      }
    }),
    
  }),
});

export const { useGetAttendeesQuery, 
  useGetConfirmedTicketIdsQuery,
  useSendConfirmationEmailMutation,
  useUpdateReservationUserInfoMutation,
  useRefundTicketMutation 
} = ReservationsApi;

