// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to instadrinkpro@gmail.com.

// ----------------------------------------------------------------------

const pt = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Olá`,
    description: `Precisa de ajuda? \n Por favor, consulte nossa documentação.`,
    documentation: `documentação`,
  },
  app: `aplicação`,
  user: `usuário`,
  list: `lista`,
  edit: `editar`,
  shop: `loja`,
  blog: `blog`,
  post: `publicação`,
  mail: `e-mail`,
  chat: `chat`,
  cards: `cartões`,
  posts: `publicações`,
  create: `criar`,
  kanban: `kanban`,
  general: `geral`,
  banking: `banca`,
  booking: `reserva`,
  profile: `perfil`,
  account: `conta`,
  product: `produto`,
  invoice: `fatura`,
  details: `detalhes`,
  checkout: `finalizar compra`,
  calendar: `calendário`,
  analytics: `análise`,
  ecommerce: `e-commerce`,
  management: `gerenciamento`,
  menu_level_1: `menu nível 1`,
  menu_level_2: `menu nível 2`,
  menu_level_3: `menu nível 3`,
  item_disabled: `item desabilitado`,
  item_label: `etiqueta do item`,
  item_caption: `legenda do item`,
  description: `descrição`,
  other_cases: `outros casos`,
  item_by_roles: `item por funções`,
  only_admin_can_see_this_item: `Apenas o administrador pode ver este item`,
  create_event: 'Criar um evento',
  label_Rows_Per_Page: 'Linhas por página',
  created_At: 'Criado em',
  location: 'Localização',
  title: 'título',
  events: 'Eventos',
  archived: 'Arquivado',
  canceled: 'Cancelado',
  Start_Date: 'Data de Início do evento',
  End_Date: 'Data de Término do evento',
  organisation: 'Organização',
  organisations: 'Organizações',
  published_events: 'Eventos Publicados',
  Add_event: 'Adicionar Evento',
  event_name: 'Nome do Evento',
  summary: 'Resumo',
  cancel: 'Cancelar',
  add: 'Adicionar',
  view_event: 'Ver Evento',
  event_title: 'Título do Evento',
  cover: 'Capa',
  cover_image: 'Imagem de Capa',
  category: 'Categoria',
  sub_category: 'Subcategoria',
  status: 'Estado',
  listed: 'Evento Público',
  listed_tooltip: "Quando ativado, seu evento será visível publicamente e pesquisável por qualquer pessoa na lista de eventos",
  online_event: 'Evento Online',
  tags: 'Tags',
  address: 'Endereço',
  city: 'Cidade',
  capacity: 'Capacidade',
  save: 'Salvar',
  Start_Time: 'Hora de Início do evento',
  End_Time: 'Hora de Término do evento',
  timezone: 'Fuso Horário',
  Save_Changes: 'Salvar Alterações',
  Quantity: 'Quantidade',
  Initial_Quantity: 'Quantidade Disponível',
  Max_Quantity: 'Máximo por Pedido',
  price: 'Preço',
  free: 'Grátis',
  Sell_Time_Limit: 'Limite de Venda',
  delete: 'Excluir',
  Edit: 'Editar',
  Add_Ticket: 'Adicionar Bilhete',
  Sell_limit: 'Limite de Venda',
  start: 'Início',
  end: 'Término',
  Access_Time_Limit: 'Tempo de acesso ao evento',
  Price_Quantity: 'Preço e Quantidade',
  Access_limit: 'Limite de Acesso',
  name: 'Nome',
  Your_event_is_already_published_on_this_link: 'Seu evento já está publicado neste link',
  archive: 'Arquivar',
  Event_Dashboard: 'Painel do Evento',
  Preview_Event: 'Visualizar Evento',
  publish: 'Publicar',
  Email_Message: 'Texto do e-mail enviado aos participantes do seu evento',
  Ticket_Message: 'Mensagem do Bilhete',
  include: 'Incluir',
  required: 'Obrigatório',
  Gender: 'Gênero',
  Birthday: 'Data de Nascimento',
  HomePhone: 'Telefone Residencial',
  yourEventIs: 'Seu evento é',
  date: 'Data',

  events_catering: 'Eventos y catering',
  events_catering_description:
    'ID es una plataforma que permite a sus clientes comprar entradas para asistir a sus eventos, así como pedir su comida y bebida digitalmente en su establecimiento.',
  create_digital_exp: 'Cree su experiencia digital',
  create_digital_exp_description:
    'Los clientes interactúan con su marca, a su manera; usted controla su presencia digital para maximizar los ingresos y la rentabilidad.',
  digital_ticketing: 'VENTA DE ENTRADAS DIGITAL',
  digital_ticketing_title: 'Añada sus eventos y venda entradas en nuestra plataforma.',
  digital_ticketing_description:
    'Cada uno de tus clientes que desee participar puede hacer clic en un enlace o escanear un código QR en el folleto digital o físico de tu evento; esto les dirigirá a la plataforma ID donde podrán seleccionar y pagar su entrada.',
  more_questions: '¿Aún tiene preguntas?',
  more_questions_description: 'Describa su caso para recibir el asesoramiento más preciso.',
  who_are_we: {
    who: '¿',
    are: 'Quiénes',
    we: 'somos',
    work: 'Trabajamos juntos para',
    futur: 'un futuro mejor en eventos y la restauración',
  },
  what_is_id: {
    title: '¿Qué es ID?',
    description:
      'ID es una plataforma que permite a tus clientes comprar entradas para asistir a tus eventos, así como pedir su comida y bebida digitalmente en tu establecimiento.',
  },
  about: {
    vision:
      'Nuestra visión: ofrecer la plataforma perfecta para un futuro mejor en eventos y catering.',
    dream_team: 'EQUIPO DE SUEÑOS',
    dream_team_description:
      'ID le proporcionará asistencia si tiene algún problema. Nuestro equipo de asistencia responderá. Un buen equipo es la clave. En el plazo de un día, y también disponemos de documentación detallada.',
  },
  testimonials: {
    title: 'TESTIMONIOS',
    who: '¿A quién le gusta ',
    service: ' nuestro servicio?',
    description:
      'Nuestro objetivo es crear la plataforma perfecta que satisfaga a todos nuestros clientes de eventos y catering. Por eso trabajamos constantemente en nuestros servicios para mejorarlos cada día escuchando las valiosas sugerencias que nuestros usuarios comparten con nosotros.',
  },

  Min_Quantity: 'Mínimo por pedido',
  save_continue: 'Guardar e continuar',
  continue_later: 'Não salve e continue mais tarde',
  Link_to_buy_tickets_for_your_event: 'Link para comprar bilhetes para o seu evento',
  back: 'Voltar',
  Complete_the_details_of_the_event_to_be_able_to_publish_it:
    'Complete os detalhes do evento para poder publicá-lo',
  publish_the_event: 'Publicar o evento',
  view: 'Ver',
  ready_events: 'Eventos prontos',
  drafted_events: 'Eventos rascunho',
  archived_events: 'Eventos arquivados',
  canceled_events: 'Eventos cancelados',
  no_events: 'Sem eventos',
  my_events: 'Meus promo eventos',
  total_sold: 'Rendimento total',
  sold_quantity: 'Número de ingressos vendidos',
  send_batch_emails: "Enviar e-mails em lote",
  event_link: 'Link do evento',
  sale_date: 'Data de venda',
  validated: 'Validado',
  confirmed: 'Confirmado',
  reimbursed: 'Reembolsado',
  About: 'Sobre',
  Name: 'Nome',
  promoters: 'PromoBoosters',
  add_promoter: 'Adicionar PromoBooster',
  choose_promoter: 'Escolher PromoBooster',
  remove: 'Remover',
  min_followers: 'Seguidores Mínimos',
  Dashboard: 'Painel de Controle',
  General: 'Geral',
  Preview: 'Visualizar',
  required_information: 'Informação Necessária',
  promo_code: 'Códigos de Promoção',
  overview: 'Visão Geral',
  preview_your_event: 'Visualizar seu evento',
  Owner: 'Proprietário',
  create_org: 'Criar uma organização',
  Account: 'Conta',
  account_settings: 'Configurações da Conta',
  Allowed_format: 'Formatos Permitidos',
  max_size: 'tamanho máximo de',
  billing: 'faturamento',
  Email_Address: 'Endereço de Email',
  Phone_Number: 'Número de Telefone',
  Country: 'País',
  State_Region: 'Estado/Região',
  Zip_Code: 'Código Postal',
  discount: 'Desconto',
  maximum_number_of_sold_tickets: 'Número Máximo de Bilhetes Vendidos',
  Featured_tickets: 'Bilhetes em Destaque',
  No_promo_code_available: 'Nenhum código de promoção disponível',
  add_promo_code: 'Adicionar código de promoção',
  This_code_is_already_used: 'Este código já está em uso!',
  discount_amount: 'Valor do Desconto',
  advanced_options: 'Opções Avançadas',
  all_tickets: 'Todos os bilhetes',
  specific_tickets: 'Bilhetes Específicos',
  Legal_info: 'Informação Legal',
  Followers: 'Seguidores',
  Total_events: 'Total de Eventos',
  Recommended: 'Recomendado',
  company_name: 'Nome da Empresa',
  company_number: 'Número da Empresa',
  Website: 'Site',
  Cover: 'Capa',
  add_organisation: 'Adicionar Organização',
  create_user: 'Criar Usuário',
  users: 'usuários',
  firstname: 'Primeiro Nome',
  lastname: 'Sobrenome',
  role: 'Função',
  access: 'Acesso',
  events_permissions: 'Permissões de Eventos',
  organisations_permissions: 'Permissões de Organizações',
  Yes: 'Sim',
  No: 'Não',
  Permissions: 'Permissões',
  Editing: 'Edição',
  Roles: 'Funções',
  Search: 'Buscar',
  Event_stats: 'Estatísticas do Evento',
  sales: 'vendas',
  Number_of_purchase: 'Número de Compras',
  Link: 'Link',
  sold: 'vendido',
  available: 'disponível',
  Tickets_status: 'Status dos Bilhetes',
  There_is_no_tickets_status_for_this_event: 'Não há status de bilhetes para este evento',
  promoter: 'PromoBooster',
  invite_user: 'Convidar Usuário',
  published: 'Publicado',
  ready: 'Pronto',
  draft: 'Rascunho',
  initial: 'inicial',
  upcoming: 'próximo',
  ongoing: 'em andamento',
  past: 'passado',
  Organisation: 'Organização',
  Bio: 'Biografia',
  Type: 'Tipo',
  Evenementiel: 'Eventos',
  Restaurant: 'Restaurante',
  Email: 'Correio eletrônico',
  Rajoutez_une_image_de_couverture_pour_votre_événement:
    'Adicione uma imagem de capa para o seu evento',
  Upload_photo: 'Carregar foto',
  Update_photo: 'Actualizar foto',
  organisation_users: 'Usuários da organização',
  Agent_liés_à_lorganisation: 'Agentes relacionados com a organização',
  Agents_en_attente_de_confirmation: 'Agentes aguardando confirmação',
  Instadrink_admin: 'Administrador do I.D',
  create_role: 'Criar um função',
  Predefined_roles: 'Funções predefinidas',
  Custom_roles: 'Funções personalizadas',
  Agents: 'Agentes',
  Organizer: 'Organizador',
  Bouncer: 'Segurança',
  Ambassador: 'Embaixador',
  promotion_groups: 'grupos de PromoBoosters',
  Dates: 'Datas',
  Tickets: 'Ingressos',
  Information: 'Informação',
  Messages: 'Mensagens',
  Promotion: 'Promoção',
  Participants: 'Participantes',
  Client: 'Cliente',
  description_placeholder:
    'Descreva aos seus futuros participantes a atmosfera excepcional que reinará no seu evento.',
  Tickets_collection: 'Coleção de ingressos',
  Stop_sell_time_is_at: 'O horário de encerramento das vendas é às',
  drag_and_drop: 'Arraste e solte para mudar a ordem',
  delete_ticket_confirmation: 'Tem certeza de que deseja excluir este bilhete?',
  Access_Time_Thresholdt: 'Horário de término do acesso ao evento',
  order: 'ordem',
  send_invitation: 'Enviar convite',
  Créez_les_tickets_pour_votre_événement: 'Crie ingressos para o seu evento',
  stop_sell_time_error_message:
    'A data de término deve ser posterior à data de início e não deve exceder a data de encerramento das vendas',
  Reset: 'Redefinir',
  Tickets_quantity_error_message:
    'A quantidade total de ingressos atingiu a capacidade máxima do evento, que é',
  Write_something_awesome: 'Escreva algo incrível...',
  Code: 'Código',
  delete_promocode_confirm_message: 'Tem certeza de que deseja excluir este código?',
  Activate_promotion: 'Ativar promoção',
  Desactivate_promotion: 'Desativar promoção',
  Configure_RP_offer: 'Configurar a oferta de PromoBooster',
  promotion_status: 'status da promoção',
  TooltipTextForPromotionGroups:
    'Nesta seção, você pode criar e gerenciar grupos de Pessoas de Recursos (PR) para seus eventos.',
  TooltipTextForEventLink: 'Publique seu evento para obter o link do seu evento.',
  TooltipTextForPromotionGroupsForReferral:
    'Nesta seção, você encontrará os grupos de PromoBooster dos quais é membro e poderá sair do grupo sempre que desejar.',
  confirm_delete_promoter: 'Tem certeza de que deseja excluir este PromoBooster?',
  Preview_promoter_link: 'Visualizar link do PromoBooster',
  Locked: 'Bloqueado',
  pending: 'Pendente',
  accepted: 'Aceito',
  rejected: 'Rejeitado',
  Publish_my_event_now: 'Publicar meu evento agora',
  publish_later: 'Publicar mais tarde',
  Your_event_is_still_draft:
    'Seu evento ainda é um rascunho, conclua a configuração para publicá-lo',
  Are_you_sure_to_publish_the_event: 'Tem certeza de publicar o evento?  ',
  pourcentage: 'percentagem',
  Rate: 'Avaliar',
  Message: 'Mensagen',
  submit: 'enviar',
  Create_group: 'criar grupo',
  Groups: 'Grupos',
  Edit_group: 'Editar grupo',
  Search_by_Name: 'Procura por nome',
  Enter_group_name: 'Insira o nome do grupo',
  No_promoters_found: 'Nenhum PromoBooster encontrado',
  Add_group: 'Adicionar grupo',
  Number_of_sales: 'Número de vendas',
  Turnover: 'Volume de negócios',
  Number_of_clients: 'Número de clientes',
  Promotion_requests: 'Solicitações de promoção',
  Requests: 'Solicitações',
  Notifications: 'Notificações',
  Referral_requests: 'Solicitações de PromoBooster',
  no_requests: 'Não há solicitações',
  request_message: 'enviou-lhe uma solicitação para convidá-lo para ser a PromoBooster de',
  Accept: 'Aceitar',
  Reject: 'Rejeitar',
  Your_invitation_to: 'Seu convite para',
  on: 'em',
  at: 'às',
  is: 'està',
  promotion_rate: 'tasa de promoción',
  consigne: 'ordem',
  Cancel_request: 'cancelar pedido',
  Send_request: 'Enviar pedido',
  No_events: 'Encontre um evento com o qual você gostaria de colaborar como PromoBooster',
  Search_for_new_events: 'Procure novos eventos',
  Search_for_Events: 'Pesquisar eventos',
  Sales_stats: 'Estatísticas de vendas',
  Total_sales: 'Vendas totais',
  Total_reservation: 'Reservas totales',
  Bienvenue_message: 'Bem-vindo ao I.D, escolha sua área de atuação',
  second_bienvenu_message:
    'Bem-vindo ao I.D, selecione sua área de atuação entre eventos e catering.',
  Continue: 'continuar',
  Event: 'Evento',
  orga_message: 'Escolha a organização sob a qual você criará seu event',
  Skip: 'Pular',
  All_steps_completed: 'Todas as etapas concluídas',
  you_have_finished: 'você terminou',
  limit_sell_message: 'Insira uma data limite e horário para a venda de ingressos',
  stopSellDate: 'Pare de vender ingressos para datas',
  status_tooltip:
    'Este campo de status calcula automaticamente a data de início e a data de término e fornece o status do evento.',
  Step: 'Etapa',
  correct_start_date: 'Corrigir a data de início passada',
  Finish: 'Terminar',
  event_name_tooltip: 'Qual é o nome do seu evento?',
  event_summary_tooltip:
    'Dê uma amostra das belas surpresas que aguardam os futuros participantes em seu evento.',
  event_description_tooltip:
    'Desta vez, descreva em detalhes o evento ao qual os futuros participantes terão a chance de participar.',
  event_cover_tooltip: 'Escolha uma foto para o seu evento.',
  organisation_tooltip: 'Escolha o nome da organização que irá hospedar o seu evento.',
  category_tooltip: 'Selecione uma categoria para o seu evento.',
  subCategory_tooltip: 'Escolha uma subcategoria para o seu evento.',
  Business: 'Negócios',
  Charity: 'Caridade',
  Causes: 'Causas',
  Community: 'Comunidade',
  Concerts: 'Concertos',
  Music: 'Música',
  Conferences: 'Conferências',
  Expos: 'Exposições',
  Fashion: 'Moda',
  Festivals: 'Festivais',
  Film: 'Cinema',
  Media: 'Mídia',
  Food: 'Comida',
  Drink: 'Bebida',
  Health: 'Saúde',
  Hobbies: 'Hobbies',
  Nightlife: 'Vida noturna',
  Other: 'Outro',
  Performing: 'Artes do',
  arts: 'espetáculo',
  Seasonal: 'Sazonal',
  Holiday: 'Férias',
  Sports: 'Esportes',
  Fitness: 'Fitness',
  Travel: 'Viagem',
  Outdoor: 'Ar livre',
  Career: 'Carreira',
  Design: 'Design',
  Educators: 'Educadores',
  Environment: 'Meio ambiente',
  Sustainability: 'Sustentabilidade',
  Finance: 'Finanças',
  Investment: 'Investimento',
  Non: 'Sem',
  Profit: 'Lucro',
  NGOs: 'ONGs',
  Real: 'Real',
  Estate: 'Imobiliária',
  Sales: 'Vendas',
  Marketing: 'Marketing',
  Startups: 'Startups',
  Small: 'Pequenas',
  Animal: 'Animal',
  Welfare: 'Bem-estar',
  Art: 'Arte',
  Auctions: 'Leilões',
  Benefit: 'Benefício',
  Walks: 'Caminhadas',
  Disaster: 'Desastre',
  Relief: 'Ajuda',
  Donor: 'Doador',
  Recognition: 'Reconhecimento',
  Educational: 'Educacionais',
  Fundraising: 'Captação de recursos',
  Homeless: 'Sem-teto',
  shelters: 'Abrigos',
  Human: 'Humano',
  Rights: 'Direitos',
  International: 'Internacional',
  Aid: 'Ajuda',
  Marches: 'Marchas',
  Poverty: 'Pobreza',
  Rallies: 'Manifestações',
  Demonstrations: 'Demonstrações',
  Ethnic: 'Étnico',
  Fairs: 'Feiras',
  Farmers: 'Agricultores',
  markets: 'Mercados',
  Fetes: 'Festas',
  Fun: 'Diversão',
  runs: 'Corridas',
  Heritage: 'Herança',
  Historic: 'Histórico',
  Language: 'Idioma',
  LGBTQ: 'LGBTQ',
  Nationality: 'Nacionalidade',
  Parades: 'Desfiles',
  Protests: 'Protestos',
  Religious: 'Religioso',
  Renaissance: 'Renascença',
  Spiritual: 'Espiritual',
  Town: 'Cidade',
  hall: 'salão',
  meetings: 'reuniões',
  Acoustic: 'Acústico',
  Americana: 'Americana',
  Bluegrass: 'Bluegrass',
  Blues: 'Blues',
  Jazz: 'Jazz',
  Classical: 'Clássica',
  Cultural: 'Cultural',
  Dance: 'Dança',
  EDM: 'Música eletrônica',
  Electronic: 'Eletrônica',
  Experimental: 'Experimental',
  Folk: 'Folk',
  Hip: 'Hip',
  Hop: 'Hop',
  Rap: 'Rap',
  Indie: 'Indie',
  Latin: 'Latina',
  Metal: 'Metal',
  Opera: 'Ópera',
  Pop: 'Pop',
  Psychedelic: 'Psicodélico',
  Reggae: 'Reggae',
  Rock: 'Rock',
  Singer: 'Cantor',
  Songwriter: 'Compositor',
  Top: 'Topo',
  World: 'Mundo',
  Colloquium: 'Colóquio',
  Conference: 'Conferência',
  Congress: 'Congresso',
  Convention: 'Convenção',
  Demonstration: 'Demonstração',
  Exhibition: 'Exibição',
  Forum: 'Fórum',
  Meeting: 'Reunião',
  Presentation: 'Apresentação',
  Round: 'Redonda',
  table: 'mesa',
  Seminar: 'Seminário',
  Symposium: 'Simpósio',
  Workshop: 'Oficina',
  Award: 'Prêmio',
  Ceremonies: 'Cerimônias',
  Screening: 'Exibição',
  Movie: 'Filme',
  Premiere: 'Estréia',
  Television: 'Televisão',
  Video: 'Vídeo',
  Game: 'Jogo',
  Education: 'Educação',
  Industry: 'Indústria',
  Science: 'Ciência',
  Technology: 'Tecnologia',
  Barbecue: 'Churrasco',
  Breakfast: 'Café da manhã',
  Brunch: 'Brunch',
  Buffet: 'Buffet',
  Cocktail: 'Coquetel',
  Mixology: 'Mixologia',
  Culinary: 'Culinária',
  workshops: 'oficinas',
  Dessert: 'Sobremesa',
  Pastry: 'Padaria',
  Tastings: 'Degustações',
  Dinner: 'Jantar',
  Farm: 'Fazenda',
  to: 'para',
  Table: 'mesa',
  Dining: 'Jantar',
  Gourmet: 'Gourmet',
  Markets: 'Mercados',
  Lunch: 'Almoço',
  Picnic: 'Piquenique',
  Wine: 'Vinho',
  Beer: 'Cerveja',
  Adult: 'Adulto',
  Anime: 'Anime',
  Comics: 'Quadrinhos',
  Book: 'Livro',
  Books: 'Livros',
  Collecting: 'Coleção',
  Cooking: 'Cozinha',
  DIY: 'Faça você mesmo',
  Crafts: 'Artesanato',
  Drawing: 'Desenho',
  Painting: 'Pintura',
  Gaming: 'Jogos',
  Gardening: 'Jardinagem',
  Home: 'Casa',
  Improvement: 'Melhoria',
  Knitting: 'Tricô',
  Photography: 'Fotografia',
  Reading: 'Leitura',
  Sewing: 'Costura',
  Writing: 'Escrita',
  Bar: 'Bar',
  Crawls: 'Rastros',
  Bingo: 'Bingo',
  Cabaret: 'Cabaré',
  Casino: 'Cassino',
  Comedy: 'Comédia',
  Clubs: 'Clubes',
  Parties: 'Festas',
  Dancehall: 'Dancehall',
  Drinks: 'Bebidas',
  Karaoke: 'Karaokê',
  Nightclubs: 'Casas noturnas',
  Open: 'Aberto',
  Mic: 'Microfone',
  Pubs: 'Bares',
  Raves: 'Raves',
  Medical: 'Médico',
  Spa: 'Spa',
  Yoga: 'Yoga',
  Circus: 'Circo',
  Exhibitions: 'Exibições',
  Magic: 'Mágica',
  Musicals: 'Musicais',
  Plays: 'Peças teatrais',
  Theater: 'Teatro',
  Christmas: 'Natal',
  Easter: 'Páscoa',
  Halloween: 'Halloween',
  Hanukkah: 'Hanukkah',
  New: 'Ano Novo',
  Year: 'Ano',
  Eve: 'Véspera',
  Thanksgiving: 'Dia de Ação de Graças',
  Archery: 'Arco e flecha',
  Baseball: 'Beisebol',
  Basketball: 'Basquete',
  Camping: 'Acampamento',
  Camps: 'Acampamentos',
  Cars: 'Carros',
  Cheer: 'Torcida',
  Climbing: 'Escalada',
  Cycling: 'Ciclismo',
  Diving: 'Mergulho',
  Exercise: 'Exercício',
  Fishing: 'Pesca',
  Football: 'Futebol',
  Golf: 'Golfe',
  Hiking: 'Caminhada',
  Hockey: 'Hóquei',
  Hunting: 'Caça',
  Jogging: 'Corrida',
  Lacrosse: 'Lacrosse',
  Motorsports: 'Esportes a motor',
  Obstacles: 'Obstáculos',
  Parachuting: 'Paraquedismo',
  Rally: 'Rali',
  Rugby: 'Rúgbi',
  Running: 'Corrida',
  Skating: 'Patinação',
  Skiing: 'Esqui',
  Snow: 'Neve',
  Snowboarding: 'Snowboard',
  Soccer: 'Futebol',
  Softball: 'Softbol',
  Sport: 'Esporte',
  Surfing: 'Surfe',
  Swimming: 'Natação',
  Water: 'Água',
  Tennis: 'Tênis',
  Volleyball: 'Vôlei',
  Walking: 'Caminhada',
  Weightlifting: 'Levantamento de peso',
  Windsurfing: 'Windsurf',
  Wrestling: 'Luta',
  Cruise: 'Cruzeiro',
  Expeditions: 'Expedições',
  Museum: 'Museu',
  visit: 'visita',
  Road: 'Estrada',
  Trips: 'Viagens',
  tours: 'Tours',
  Rp_request: 'te enviou pedido para ser o PromoBooster do evento dele.',
  warning_message: 'Por favor, pressione "adicionar" primeiro para salvar seu ticket e continuar',
  correct_date_tooltip: 'A data de início selecionada para o seu evento já passou.',
  step1:
    'En este paso, encontrará las estadísticas de ventas del evento y la lista de asistentes al evento.',
  step2: 'En este paso, puede editar los detalles del evento.',
  step3: 'En este paso, puede editar las fechas y horas del evento.',
  step4:
    'En este paso, puede agregar nuevos boletos para su evento o editar los boletos existentes.',
  step5: 'En este paso, puede publicar o archivar su evento.',
  step6:
    'En este paso, encontrará la vista del evento para clientes en la vista de escritorio o móvil.',
  step7: 'En este paso, encontrará una descripción general de los detalles de su evento.',
  step8: 'Neste passo, você pode obter informações sobre o evento.',
  stepCodePromo: 'En este paso, puede agregar códigos promocionales para los boletos.',
  step10:
    'En este paso, puede gestionar los PromoBooster de su evento, crear un grupo de PromoBooster o enviar solicitudes para que el PromoBooster sea PromoBooster de su evento.',
  step11: 'En este paso, encontrará la lista de los asistentes.',
  total_sold_tooltip: 'Este é o preço total de venda para os ingressos do evento.',
  sold_quantity_tooltip: 'Esta é a quantidade total de ingressos vendidos para o evento.',
  link_tooltip: 'Este é o link do evento para comprar seus ingressos.',
  table_tooltip: 'Aqui está a tabela onde você encontrará a lista dos participantes deste evento.',
  create_event_button_tooltip: 'Clique aqui para criar um novo evento.',
  events_table_tooltip:
    'Esta é a tabela de eventos onde você pode encontrar os detalhes dos seus eventos.',
  You_have_no_organisations: 'Você não tem organizações',
  alert_orga_title: 'Você precisa criar uma organização para poder criar um evento depois.',
  Configurate_here: 'Configure aqui',
  legal_verification: 'Verificação legal da sua conta',
  alert_stripe_configuration_title:
    'Por favor, complete suas informações legais para ativar sua conta e poder vender seus ingressos.',
  Bank_balance: 'Saldo bancário',
  View_my_Stripe_account: 'Ver minha conta Stripe',
  Your_balance: 'Seu saldo',
  Add_new_card: 'Adicionar novo cartão',
  Name_on_card: 'Nome no cartão',
  Card_number: 'Número do cartão',
  Expiration_date: 'Data de validade',
  QRcode_tooltip: 'Clique aqui para personalizar e baixar o código QR do seu evento',
  File_Type: 'Tipo de arquivo',
  Size: 'Tamanho',
  Color: 'Cor',
  Background_Color: 'Cor de fundo',
  Download_QR_Code: 'Baixar código QR',
  Download_Invoice: 'Baixar fatura',
  show_qr_code: 'Mostrar código QR',
  Close: 'Fechar',
  Customize_QR_Code: 'Personalizar código QR',
  no_users_yet: 'Nenhum usuário ainda',
  plz_invite_users: 'Por favor, convide usuários',
  invite_organizer: 'Convidar organizador',
  invite: 'convidar',
  invite_owners: 'convidar proprietários',
  register: 'Registrar',
  password: 'Senha',
  confirm_password: 'Confirmar Senha',
  email_adress: 'Endereço de Email',
  division_agent: 'Agente',
  division_promoter: 'PromoBooster',
  iam_owner: 'Eu sou um Proprietário',
  iam_owner_desc: 'Gerencie suas organizações, restaurantes e eventos',
  iam_amb: 'Eu sou um Embaixador',
  iam_amb_desc: 'Promova organizações e ganhe comissões',
  iam_ref: 'Eu sou um PromoBooster',
  iam_ref_desc: 'Indique eventos e ganhe comissões',
  start_free: 'Começar Grátis',
  no_credit_card: 'Nenhum Cartão de Crédito Necessário',
  have_account: 'Já tem uma conta?',
  by_register_1: 'Ao me registrar, concordo com os ',
  by_register_2: 'Termos de Serviço',
  by_register_3: ' e a ',
  by_register_4: 'Política de Privacidade do I.D.',
  manager_better_register: 'Gerencie eventos de forma mais eficiente com o I.D',
  referral: 'PromoBooster',
  first_name_required: 'Nome é obrigatório',
  first_name_short: 'Nome é muito curto - deve ter no mínimo 3 caracteres.',
  first_name_long: 'Nome é muito longo - deve ter no máximo 50 caracteres.',
  first_name_alpha: 'Nome só pode conter caracteres alfabéticos.',
  last_name_required: 'Sobrenome é obrigatório',
  last_name_short: 'Sobrenome é muito curto - deve ter no mínimo 3 caracteres.',
  last_name_long: 'Sobrenome é muito longo - deve ter no máximo 50 caracteres.',
  last_name_alpha: 'Sobrenome só pode conter caracteres alfabéticos.',
  email_invalid: 'O Email deve ser um endereço de email válido',
  email_required: 'Email é obrigatório',
  password_required: 'Senha é obrigatória',
  password_short: 'Senha é muito curta - deve ter no mínimo 8 caracteres.',
  password_alpha: 'Senha só pode conter letras latinas.',
  password_uppercase: 'Senha deve conter pelo menos uma letra maiúscula.',
  password_number: 'Senha deve conter um número.',
  confirm_password_match: 'As senhas devem ser iguais',
  confirm_password_required: 'Confirmar Senha é obrigatório',
  division_required: 'Divisão é obrigatória',
  division_invalid: 'Divisão deve ser PromoBooster ou Agente',
  role_required: 'Função é obrigatória',
  role_invalid_promoter: 'Função inválida para PromoBooster',
  role_invalid_agent: 'Função inválida para Agente',
  login: 'Entrar',
  fr: 'Français',
  en: 'English',
  es: 'Espagnol',
  pt: 'Portugais',
  lang: 'Langue',
  creating_account: 'Criação de Conta em Andamento',
  creating_account_desc: 'Finalizando a Configuração da Sua Conta',
  creating_account_info:
    'Sua conta está sendo configurada. Por favor, permaneça nesta página até que o processo esteja completo. Agradecemos a sua paciência!',
  accept_invitation_info:
    'Por favor, note que, após aceitar o convite abaixo, você receberá um e-mail de verificação. Após a verificação, você poderá completar a configuração da sua conta, incluindo as opções para alterar seu nome e idioma preferido.',
  invitation_already_processed: "Este convite já foi processado. Por favor, verifique seu painel de eventos para mais informações.",
  verify_email: 'Verifique Seu Email',
  verify_email_desc: 'Conta Criada com Sucesso',
  verify_email_info:
    'Parabéns por configurar sua conta! Por favor, verifique seu e-mail para confirmar sua conta e acessar todas as funcionalidades.',
  wait_verify: 'Por favor, aguarde um momento enquanto verificamos seu e-mail.',
  wait_redirect: 'Não feche esta janela até ser redirecionado para o painel de controle.',
  initialAccountHeader: 'Finalize sua conta I.D',
  initialAccountDescription:
    'Bem-vindo de volta! Basta definir sua senha abaixo para finalizar a configuração da sua conta.',
  initialAccountButton: 'Concluir Configuração',
  processingAccountHeader: 'Configurando sua conta...',
  processingAccountDescription:
    'Por favor, aguarde um momento enquanto confirmamos seus detalhes e protegemos sua conta.',
  processingAccountButton: 'Concluindo...',
  successAccountHeader: 'Sucesso! Conta Pronta',
  successAccountDescription:
    'Sua conta foi configurada com sucesso e você está sendo conectado. Você será redirecionado para o painel em breve.',
  successAccountButton: 'Redirecionando...',
  errorAccountHeader: 'Algo deu errado',
  errorAccountDescription:
    'Ocorreu um problema com a configuração da sua conta. O link pode ser inválido ou ter expirado, ou o endereço de e-mail pode estar incorreto.',
  errorAccountButton: 'Tentar Novamente',
  linkexpiredAccountHeader: 'Verifique Seu E-mail',
  linkexpiredAccountDescription: "Houve um problema com a configuração da sua conta. O link pode estar inválido ou expirado, ou o endereço de e-mail pode estar incorreto.",
  verification_expired: "Falha na configuração da conta! O link de verificação da sua conta expirou.",
  new_verification_link_sent: "Um novo link de verificação foi enviado para o seu endereço de e-mail.",
  login_welcome: 'Olá, Bem-vindo',
  login_connect: 'Conecte-se ao I.D',
  login_type: 'Digite seus detalhes abaixo.',
  login_no_account: 'Não tem uma conta?',
  login_register: 'Inscrever-se',
  owner_total_part: 'Esta é a participação do proprietário na venda de ingressos para o evento.',
  my_share: 'Minha parte',
  Remember_password: 'Lembrar senha',
  forgot_password: 'Esqueceu sua senha?',
  create_your_first_organization: 'Crie sua primeira organização',
  create_organization_step_description:
    'Para começar a gerenciar seus eventos, crie sua primeira organização. Você pode criar quantas organizações quiser depois.',
  create_your_first_event: 'Crie seu primeiro evento',
  create_event_step_description:
    'Agora que você criou sua primeira organização, é hora de criar brevemente seu primeiro evento. Você pode editá-lo detalhadamente mais tarde.',
  congratulations: 'Parabéns!',
  last_step_description:
    'Agora você pode navegar até a lista de eventos e continuar criando seu evento.',
  create_your_first_restaurant: 'Crie seu primeiro restaurante',
  create_restaurant_step_description:
    'Para começar a gerenciar suas mesas, crie seu primeiro restaurante. Você pode criar quantos restaurantes quiser depois.',
  create_restaurant_last_step_description:
    'Agora você pode navegar até a lista de restaurantes e continuar criando suas mesas.',
  Logout: 'Sair',
  Settings: 'Configurações',
  create_store: 'Criar restaurante',
  add_category: 'Adicionar categoria',
  select_store: 'Selecionar um restaurante',
  add_new_product: 'Adicionar novo produto',
  add_new_category: 'Adicionar nova categoria',
  products: 'Produtos',
  categories: 'Categorias',
  unavailable: 'Indisponível',
  composite: 'Composto',
  alcoholic: 'Alcoólico',
  Available: 'Disponível',
  Description: 'Descrição',
  simple: 'Simples',
  search_product: 'Buscar produto...',
  min_price: 'Preço mínimo',
  max_price: 'Preço máximo',
  add_product: 'Adicionar produto',
  edit_product: 'Editar produto',
  product_details: 'Detalhes do produto',
  composition_items: 'Itens de composição',
  caption: 'Legenda',
  extra: 'Extra',
  max_quantity: 'Quantidade máxima',
  add_composition: 'Adicionar composição',
  items: 'Itens',
  item_name: 'Nome do item',
  add_item: 'Adicionar item',
  preparation_time: 'Tempo de preparo',
  Orders: 'Pedidos',
  current_orders: 'Pedidos Atuais',
  store: 'Restaurante',
  no_stores_message: 'Você não possui restaurantes para ver pedidos, por favor crie uma primeiro',
  Pending: 'Pendente',
  in_preparation: 'Em Preparação',
  Preparation: 'Preparação',
  order_is_ready: 'Pedido pronto',
  finish_order: 'Finalizar pedido',
  total: 'Total',
  order_of: 'Pedido de',
  Contact: 'Contato',
  reservations_shares: 'Compartilhamento de reservas',
  client_name: 'Nome do cliente',
  total_amount: 'Valor total',
  owner_share: 'Participação do proprietário',
  instadrink_share: 'Participação do I.D',
  rp_share: 'Participação do PromoBooster',
  ambassador_share: 'Participação do embaixador',
  shares_distribution: 'Distribuição de ações',
  group_name: 'Nome do grupo',
  group_members: 'Membros do grupo',
  created_by: 'Criado por',
  leave_group: 'Sair do grupo',
  with_product: 'Com produto',
  all_categories: 'Todas as categorias',
  inclusives: 'Inclusivos',
  closing_Time: 'Horário de fechamento',
  open: 'Aberto',
  Rush_hour: 'Hora de pico',
  preview_store: 'Visualização do restaurante',
  Delete_account: 'Excluir conta',
  Are_you_sure_you_want_to_delete_your_account: 'Tem certeza de que deseja excluir sua conta',
  thanks_join_invite: 'Obrigado por se juntar a nois. Seja bem-vindo ao I.D!',
  delete_account_page_message:
    'Ao optar por excluir sua conta, você está escolhendo a remoção permanente de todos os seus dados de nosso sistema.',
  account_created_check_email:
    'Conta criada com sucesso. Verifique seu e-mail para confirmar sua conta e acessar todas as funcionalidades.',
  verify_before_expire: 'Verificar antes de expirar',
  if_mistake_contact_instadrink: 'Se houver um erro, entre em contato com o I.D',
  invitation_rejected: 'Convite rejeitado',
  invite_owner: 'Eu gostaria de convidar um proprietário',
  invite_referrer: 'Eu gostaria de convidar um PromoBooster',
  invite_ambassador: 'Eu gostaria de convidar um ambassador',
  invite_more_users: 'Convidar mais usuários',
  your_organisation_staff: 'Seu pessoal da organização',
  inv_org_desc: 'O organizador do seu evento',
  inv_bnc_desc: 'O controlador de acesso do seu evento',
  invite_more_staff: 'Convidar mais personal',
  since: 'Desde',
  delete_event_modal: '¿Estás seguro de que quieres eliminar el evento',
  publish_event_modal: '¿Estás seguro de que quieres publicar el evento',
  cancel_event_modal: '¿Estás seguro de que quieres cancelar el evento',
  archive_event_modal: '¿Estás seguro de que quieres archivar el evento',
  duplicate_event: 'Duplicar evento',
  duplicate: 'Duplicar',
  reservations: 'Reservas',
  multiple_persons: 'Várias pessoas',
  persons_number: 'Número de pessoas',
  vatRate: 'Taxa do IVA',
  price_TTC: 'Preço com impostos',
  Occupation: 'Ocupação',
  priceHt: 'Preço sem IVA',
  promobooster_mode: 'Modo Promobooster',
  promo_mode: 'Modo Promo',
  Amount: 'Montante',
  Percentage: 'Percentagem',
  include_owners: 'incluir proprietários',
  add_anonymous_promoter: 'Adicionar SpyBooster',
  Confirm_Name: 'Confirmar nome',
  Add_Anonymous_Name: 'Adicionar SpyBooster',
  ask_for_invoice: 'pedir fatura',
  add_invoice_button: 'Adicione  ',
  enter_valid_invoice: 'Introduza uma fatura válida',
  Compan_Name_Last_Name: 'Empresa/Nome/Apelido',
  tva: 'TVA',
  adresse: 'Endereço',
  postal_code: 'Código Postal',
  country: 'País',
  additional_information: 'Informações adicionais',
  invoice_information: 'Cobrança',
  survey_information: 'Questionário',
  know_from_invoice: 'Que informação exige da sua fatura?',
  add_invoice: 'Adicionar novo campo',
  active_invoice: 'Ative a fatura',
  actived_invoice: 'A fatura está ativada',
  preview_your_share: 'Visualizar Distribuição  ',
  Number_of_event: 'Número do evento',
  time_of_creation: 'Criar em',
  Owner_details: 'Detalhes do proprietário',
  greetings: 'Saudações,',
  you_have_been_invited: 'Você foi convidado para se juntar ao I.D 🎉',
  plz_wait_a_moment: 'Por favor, espere um momento',
  thanks_time: 'Hora dos agradecimentos',
  averagePreparationTime: 'Tempo médio de preparação',
  minutes: 'minutos',
  seconds: 'segundos',
  select_menu: 'Selecione um menu',
  capacity_warning_message: 'As capacidades estão esgotadas em comparação com a capacidade total.',
  add_product_alert:
    'Para adicionar um produto aos seus bilhetes, por favor, vincule uma loja ao seu evento',
  remaining_places: 'lugares restantes',
  navigate_to_event_menu: 'Navegar até o menu do evento',
  you_have_no_store: 'Você não possui um restaurante',
  reject_order: 'Rejeitar pedido',
  add_Waiter: 'Adicionar garçom',
  add_room: "Adicionar Quarto",
  room_name: "Nome Do Quarto",
  "campaigns": {
    "title": "Campanhas de e-mail",
    "audience": "Audência",
    segment: "Segmento",
    "createNew": "Criar nova campanha",
    "name": "Nome",
    "template": "Modelo",
    "scheduledTime": "Hora agendada",
    "status": "Status",
    "actions": "Ações",
    "backToList": "Voltar para a lista de campanhas",
    "info": "Informações da campanha",
    "mails": "E-mails",
    "email": "E-mail",
    "emailStatus": "Status do e-mail",
    "confirmDeletion": "Confirmar exclusão",
    "deleteConfirmationMessage": "Tem certeza de que deseja excluir esta campanha de e-mail? Esta ação não pode ser desfeita.",
    "cancel": "Cancelar",
    "delete": "Excluir",
    "edit": "Editar campanha",
    "create": "Criar nova campanha",
    "campaignName": "Nome da campanha",
    "save": "Salvar",
    "selectTemplate": "Selecionar um modelo",
    "createNewTemplate": "Criar novo modelo",
    "audienceType": {
      "title": "Audiência",
      "all": "Todos os contatos",
      "segment": "Segmento específico",
      "eventBuyers": "Compradores de ingressos"
    },
    "drawer": {
      "create": "Criar Campanha",
      "edit": "Editar Campanha",
      "name": "Nome da Campanha",
      "audienceType": "Tipo de Público",
      "segment": "Selecionar Segmento",
      "draftInfo": "A campanha será salva como rascunho"
    },
    "campaignStatus": {
      "pending": "Pendente",
      "scheduled": "Programado",
      "sending": "Enviando",
      "launched": "Enviado",
      "failed": "Falhou",
      "template-ready": "Modelo pronto"
    },
    "subject": "Assunto do email",
    "subjectHelperText": "Você pode personalizar o assunto do modelo para esta campanha",
    "launchType": {
      "title": "Tipo de lanzamento",
      "immediate": "Lançar imediatamente",
      "scheduled": "Agendar para mais tarde"
    },
    "emailstatus": {
      "email.sent": "Enviado",
      "email.delivered": "Entregue",
      "email.delivery_delayed": "Entrega Atrasada",
      "email.complained": "Reclamação Recebida",
      "email.bounced": "Devolvido",
      "email.opened": "Aberto",
      "email.clicked": "Clicado",
      "email.unsubscribed": "Cancelada Inscrição",
      "email.failed": "Falha no Envio",
      "email.blocked": "Bloqueado",
      "email.dropped": "Descartado",
      "email.deferred": "Adiado",
      "email.rejected": "Rejeitado"
    },
    "schedule": {
      send: "Enviar",
      action: "Agendar",
      "title": "Agendar Campanha",
      "info": "Agendar envio para a campanha: {{name}}",
      scheduledTimeHelper: "Escolha uma data e hora futura para enviar sua campanha",
      "errors": {
        "scheduleTimeRequired": "Por favor selecione um horário de agendamento",
        "testEmailRequired": "Por favor insira um endereço de email de teste",
        "scheduleFailed": "Falha ao agendar a campanha",
        "testSendFailed": "Falha ao enviar o email de teste"
      },
      "success": {
        "testSent": "Email de teste enviado com sucesso",
        "launched": "Campanha lançada com sucesso",
        "scheduled": "Campanha agendada com sucesso"
      }
    },
    "stats": {
      "title": "Estatísticas da Campanha",
      "delivered": "Entregues",
      "opened": "Abertos",
      "clicked": "Clicados",
      "bounced": "Rejeitados"
    }
  },
  "Include_Platform_Fees": "Incluir taxas de plataforma",
  "Final_Price": "Preço final",
  "Platform_Fee_Info": "Quando ativado, a comissão da plataforma de 2,5% será adicionada ao preço do ingresso e paga pelos participantes. Se desativado, esta comissão será deduzida da sua receita de bilheteria.",
  "Breakdown_Price": "Detalhamento do preço",
  "Ticket_Price": "Preço do ingresso",
  "Service_Fees": "Gastos de servicio",
  "Per_Ticket": "por bilhete",
  "Platform_Fee": "Taxa da plataforma",
  "Total": "Total",
  "contacts": {
    "totalContacts": "{{count}} contatos",
    "import": "Importar contatos",
    "export": "Exportar para CSV",
    "deleteSelected": "Excluir selecionados",
    "search": "Pesquisar",
    "noContactsToExport": "Não há contatos para exportar.",
    "errorDeletingContacts": "Erro ao excluir contatos",
    "errorLoadingContacts": "Erro ao carregar contatos",
    "confirmDeletion": "Confirmar exclusão",
    "confirmDeletionMessage": "Tem certeza de que deseja excluir os {count} contatos selecionados?",
    "cancel": "Cancelar",
    "delete": "Excluir",
    "dragAndDropInstruction": "Arraste e solte um arquivo CSV ou XLSX aqui, ou clique para selecionar",
    "file": "Arquivo: {name}",
    "removeFile": "Remover arquivo",
    "informationMapping": "Mapeamento de informações",
    "select": "Selecionar",
    "additionalFields": "Campos adicionais",
    "errorParsingFile": "Erro ao analisar o arquivo. Por favor, tente novamente.",
    "contactsImportedSuccessfully": "Contatos importados com sucesso",
    "errorImportingContacts": "Erro ao importar contatos. Por favor, tente novamente.",
    "fields": {
      "email": "E-mail",
      "firstName": "Primeiro Nome",
      "lastName": "Sobrenome",
      "phone": "Telefone",
      "age": "Idade",
      "gender": "Gênero",
      "country": "País",
      "zone": "Zona",
      "postalCode": "Código Postal"
    }
  },
  "no_confirmed_tickets_to_export": "Não há ingressos confirmados para exportar",
  "export_confirmed_ticket_ids_tooltip": "Exportar IDs de ingressos confirmados",
  "loading": "Carregando...",
  "export_confirmed_tickets": "Exportar Ingressos Confirmados ({{count}})",
  "segments": {
    "create": {
      "title": "Criar Novo Segmento",
      "selectType": "Selecione um tipo de segmento",
      "dynamicSegment": {
        "title": "Segmento de Filtro Dinâmico",
        "description": "Aplique uma lista de atributos para selecionar uma parte de seus contatos. Seus contatos serão automaticamente adicionados ou removidos deste segmento com base nos filtros escolhidos."
      },
      "manualSegment": {
        "title": "Segmento Manual via CSV",
        "description": "Você pode importar uma lista de contatos de um arquivo CSV para agrupá-los em um segmento. Você pode usar esse recurso para reimportar contatos existentes como um novo segmento."
      },
      "step": "Etapa",
      "of": "de",
      removeFile: "Remover arquivo",
      "newsletterContacts": "{{count}} / 2000 contatos inscritos na newsletter já importados.",
      "selectAgeRange": "Selecione o intervalo de idade que deseja filtrar:",
      "averageAge": "Média: {{age}} anos",
      "infoKnownFor": "Esta informação é conhecida para {{count}} contatos",
      "selectCountries": "Selecionar um ou mais países",
      "selectZones": "Selecionar zonas",
      "addPostalCodes": "Adicionar códigos postais (separados por vírgula)",
      "selectLanguage": "Selecionar idioma",
      "whenAdded": "Quando este contato foi adicionado à sua comunidade",
      "contactsImported": "contatos inscritos em sua newsletter já importados.",
      "dropFileHere": "Solte seu arquivo CSV, XLS ou XLSX aqui",
      "fileRequirements": "Seu arquivo deve ter pelo menos uma coluna com o endereço de e-mail dos contatos. E para enviar newsletters, deve ter uma coluna de inscrição na newsletter (sim/não).",
      "removeFilters": "Remover filtros",
      "contactInformation": "INFORMAÇÕES DE CONTATO",
      "marketing": "MARKETING",
      "history": "HISTÓRICO",
      "fieldMapping": "Mapeamento de Campos",
      "additionalFields": "Campos Adicionais",
      "segmentName": "Nome do Segmento",
      "description": "Descrição",
      "matchingContacts": "contatos correspondentes",
      "back": "Voltar",
      "cancel": "Cancelar",
      "next": "Próximo",
      "createSegment": "Criar Segmento",
      "importContacts": "Importar contatos como segmento"
    },
    "fieldsMapping": {
      "name": "Nome",
      "email": "Email",
      "firstName": "Nome",
      "lastName": "Sobrenome",
      "phone": "Telefone",
      "age": "Idade",
      "gender": "Gênero",
      "country": "País",
      "zone": "Zona",
      "postalCode": "Código postal"
    },
    "filters": {
      activeFilters: "Filtros ativos",
      "hasEmail": "Tem um e-mail",
      "emailVerified": "E-mail verificado",
      "hasPhone": "Tem um número de telefone",
      "age": "Idade",
      "gender": "Gênero",
      "location": "Localização",
      "language": "Idioma",
      "dateAdded": "Data de Adição",
      "selectCountries": "Selecione um ou mais países",
      "selectZones": "Selecione zonas",
      "postalCodes": "Adicionar códigos postais (separados por vírgulas)",
      "selectLanguage": "Selecionar idioma",
      "allTime": "Todo o tempo",
      "today": "Hoje",
      "yesterday": "Ontem",
      "last7days": "Últimos 7 dias",
      "last14days": "Últimos 14 dias",
      "last30days": "Últimos 30 dias",
      "last90days": "Últimos 90 dias",
      "customDates": "Datas personalizadas",
      "from": "De",
      "to": "Até",
      "events": {
        "title": "Participação em eventos",
        "hasAttendedEvents": "Eventos participados",
        "attendanceInfo": "Participou de {{count}} eventos ou mais",
        "selectRange": "Selecione o número mínimo de eventos participados",
        "infoTooltip": "Isso corresponderá a usuários que participaram de pelo menos esta quantidade de eventos"
      }
    }
  },
  "segment": {
    "Create": {
      "matchingContacts": "contatos correspondentes",
      "back": "Voltar",
      "cancel": "Cancelar",
      "next": "Próximo",
      "importContacts": "Importar contatos",
      "title": "Criar segmento"
    }
  },
  "stripe_connect": {
    "setup_stripe_account": "Configurar conta Stripe",
    "complete_stripe_setup": "Completar configuração",
    "alert_stripe_incomplete_setup": "A configuração da sua conta Stripe está incompleta. Por favor, complete o processo de verificação para começar a receber pagamentos pelos seus eventos.",
    "error_creating_stripe_account": "Erro ao criar conta Stripe. Por favor, tente novamente mais tarde.",
    "error_creating_stripe_link": "Erro ao gerar link de configuração do Stripe. Por favor, tente novamente mais tarde.",
    "legal_verification": "Verificação Legal Necessária",
    "alert_stripe_configuration_title": "Para receber pagamentos pelos seus eventos, você precisa configurar sua conta Stripe Connect. Esta etapa é obrigatória para conformidade legal e processamento seguro de pagamentos."
  },
  "templates": {
    "createNew": "Criar novo modelo",
    "subject": "Assunto do email",
    "subjectRequired": "O assunto do email é obrigatório",
    "createSuccess": "Modelo criado com sucesso",
    "createError": "Falha ao criar o modelo"
  },
  "survey": {
    "title": "Criador de Pesquisa de Eventos",
    "enable": "Ativar Pesquisa",
    "noResponses": "Ainda não há respostas",
    "totalResponses": "Total de respostas: {{count}}",
    "noSurveyData": "Não há dados de pesquisa disponíveis",
    "errorLoading": "Erro ao carregar dados da pesquisa",
    "question": {
      "title": "Pergunta {{number}}",
      "required": "Obrigatório",
      "text": "Texto da Pergunta",
      "type": "Tipo de Pergunta",
      "types": {
        "shortText": "Texto Curto",
        "longText": "Texto Longo",
        "radio": "Botões de Opção",
        "checkbox": "Caixas de Seleção"
      },
      "options": "Opções:",
      "addOption": "Adicionar Opção",
      "submit": "Enviar Pergunta",
      "validation": {
        "emptyText": "O texto da pergunta não pode estar vazio.",
        "radioOptions": "A pergunta de opção única deve ter pelo menos uma opção.",
        "checkboxOptions": "A pergunta de múltipla escolha deve ter pelo menos uma opção."
      }
    },
    "addQuestion": "Adicionar Pergunta",
    "answersSummary": "Resumo das Respostas"
  },
  promoboosters: {
    "referral_sales": "Vendas por Indicação",
    "other_sales": "Outras Vendas",
    "available": "Disponível",
    "No_tickets_sold_yet": "Nenhum ingresso vendido ainda",
    "ticket_category": "Categoria do Ingresso",
    "total_sold": "Total Vendido",
    "initial_quantity": "Quantidade Inicial"
  },
  guests: {
    guestLists: 'Listas de convidados',
    addList: 'Adicionar lista',
    addGuest: 'Adicionar convidado',
    searchGuests: 'Pesquisar convidados',
    addNewList: 'Adicionar nova lista',
    listName: 'Nome da lista',
    guestList: 'Lista de convidados',
    sendEmailsToNewGuests: 'Enviar emails para novos convidados',
    single: 'Individual',
    import: 'Importar',
    text: 'Texto',
    name: 'Nome',
    email: 'Email',
    totalTickets: 'Total de ingressos',
    freeTickets: 'Ingressos gratuitos',
    dragDropCsv: 'Arraste e solte um arquivo CSV aqui, ou clique para selecionar um arquivo',
    guestDetailsPlaceholder: 'Digite os detalhes dos convidados (um por linha): Nome, IngressosTotal+IngressosGratuitos, Email',
    importGuests: 'Importar convidados',
    editGuest: 'Editar convidado',
    update: 'Atualizar',
    delete: 'Excluir',
    confirmDelete: 'Confirmar exclusão',
    confirmDeleteMessage: 'Tem certeza que deseja excluir este convidado? Esta ação não pode ser desfeita.',
    cancel: 'Cancelar',
    mapColumns: 'Mapear colunas',
    emailLabel: 'Email',
    ticketsInfo: 'Ingressos',
    "deleteList": "Excluir Lista de Convidados",
    "deleteListConfirmation": "Tem certeza que deseja excluir a lista de convidados: ",
    "deleteListWarning": " Isso também excluirá todos os convidados desta lista e não poderá ser desfeito.",
    "invalidFileType": "Tipo de arquivo inválido. Por favor, envie um arquivo CSV ou Excel (.csv, .xlsx, .xls)",
    "csvImportInstructions": "Carregue um arquivo CSV ou Excel com as informações dos convidados. O arquivo deve conter as seguintes colunas:",
    "csvRequiredColumns": "Colunas obrigatórias",
    "nameColumnDescription": "Nome completo do convidado (obrigatório)",
    "emailColumnDescription": "Endereço de e-mail do convidado (opcional)",
    "totalTicketsColumnDescription": "Número total de ingressos alocados (mínimo 1)",
    "freeTicketsColumnDescription": "Número de ingressos gratuitos (deve ser menor ou igual ao total de ingressos)"

  },
  "confirm_refund": "Confirmar reembolso",
  "refund_confirmation_message": "Tem certeza que deseja reembolsar este ingresso? Esta ação não pode ser desfeita.",
  "refund_amount": "Valor do reembolso",
  "processing": "Processando...",
  "only_confirmed_tickets_can_be_refunded": "Apenas bilhetes confirmados podem ser reembolsados",
  "refund_ticket": "Reembolsar bilhete",
  send_confirmation_email: "Enviar email de confirmação",
  can_only_send_email_for_confirmed_or_validated: "So pode enviar email para ingressos confirmados ou validados",
  "deduct_platform_fee": "Deduzir Taxa da Plataforma",
  "platform_fee_toggle_description": "Quando ativado, a taxa da plataforma será deduzida do valor do reembolso",
  'Remove Deformation': 'Remove Deformation',
  "original_price": "Preço Original",
  "platform_fee": "Taxa da Plataforma",
  "platform_fee_deduction": "Dedução da Taxa da Plataforma",
  "final_refund_amount": "Valor Final do Reembolso",
  'Deformed Price': 'Deformed Price',
  'Final Price': 'Final Price',
  'No deformations available': 'No price deformations available',
  'Price deformation added': 'Price deformation added successfully',
  'Price deformation removed': 'Price deformation removed',
  'Price Deformations Management': 'Price Deformations Management',
  'Add Price Deformation': 'Add Price Deformation',
  'No price deformations defined yet': 'No price deformations defined yet',
  'Inactive': 'Inactive',
  'off': 'off',
  'Priority': 'Priority',
  'Domains': 'Domains',
  'Are you sure you want to delete this price deformation?': 'Are you sure you want to delete this price deformation?',
  'Price deformation deleted successfully': 'Price deformation deleted successfully',
  'Failed to delete price deformation': 'Failed to delete price deformation',
  "Ticket Restrictions": "Ticket Restrictions",
  "Add Access Restriction": "Add Access Restriction",
  "Access Restriction": "Access Restriction",
  "Allowed Domains": "Allowed Domains",
  "Restriction Rules": "Restriction Rules",
  "A ticket can have either multiple price deformations OR one access restriction, but not both.": "A ticket can have either multiple price deformations OR one access restriction, but not both.",
  "Adding an access restriction will remove all price deformations. Continue?": "Adding an access restriction will remove all price deformations. Continue?",
  "Access_Restriction_Description": "O acesso a ingressos pode ser restrito pelo domínio do seu e-mail.",
  total_tickets_sold: 'Ingressos vendidos',
  total_revenue: 'Receita total',
  eventTicketStatistics: 'Estatísticas de ingressos do evento',
  eventCapacity: 'Capacidade do evento',
  totalTickets: 'Total de ingressos',
  totalReservations: 'Total de reservas',
  ticketName: 'Nome do ingresso',
  initialQuantity: 'Quantidade inicial',
  soldQuantity: 'Quantidade vendida',
  noTicketData: 'Nenhum dado de ingresso disponível',
  errorLoadingTickets: 'Erro ao carregar dados dos ingressos',
  na: 'N/D',
  "confirm_send_email": "Confirmar envio de e-mail",
  "send_email_confirmation_message": "Tem certeza de que deseja enviar o e-mail de confirmação para este destinatário?",
  "recipient": "Destinatário",
  "sending": "Enviando...",
  "send": "Enviar",
  "ticketInvitation": {
    "import": {
      "steps": {
        "setup": "Configuração",
        "preview": "Visualização e Importação"
      },
      "next": "Próximo",
      "back": "Voltar",
      "summary": "Resumo da importação",
      "totalRecords": "Total de registros",
      "selectedTicket": "Categoria de ingresso selecionada",
      "title": "Importar convidados",
      "progress": "Processando {{current}}/{{total}} ({{success}} com sucesso, {{failed}} falhados)",
      "dragOrSelect": "Solte seu arquivo CSV aqui ou clique para selecionar",
      "dropHere": "Solte o arquivo CSV aqui",
      "mapColumns": "Mapear colunas CSV",
      "previewTitle": "Prévia",
      "records": "registros",
      "firstThreeRecords": "Três primeiros registros",
      "importButton": "Importar {{count}} convites",
      "errors": {
        "unknown": "Ocorreu um erro desconhecido",
        "csvEmpty": "O arquivo CSV está vazio",
        "invalidFormat": "Formato CSV inválido",
        "parseError": "Erro ao analisar arquivo CSV",
        "fileTooLarge": "Arquivo muito grande (máx 5MB)",
        "invalidFileType": "Apenas arquivos CSV são permitidos",
        "invalidDataRows": "{{count}} linhas contêm dados inválidos",
        "missingMapping": "Por favor, mapeie todas as colunas necessárias",
        "creationFailed": "Falha ao criar convites"
      },
      "success": {
        "partial": "Importação concluída: {{success}} com sucesso, {{failed}} falhados.\nDetalhes:\n{{details}}",
        "complete": "{{count}} convites criados com sucesso"
      },
      "explanation": {
        "main": "Importe ingressos para múltiplos participantes usando um arquivo CSV. Perfeito para migrar de outros sistemas de bilheteria ou criar ingressos para uma lista predefinida de convidados.",
        "features": "Esta ferramenta de importação vai:",
        "feature1": "Criar ingressos digitais com códigos QR únicos para cada participante",
        "feature2": "Enviar e-mails de confirmação aos participantes com seus códigos QR",
        "feature3": "Permitir atribuir categorias específicas de ingressos a todos os convidados importados"
      }
    }
  },
  "tickets": {
    price_per_ticket_ht: "por ingresso sem impostos",
    "hide_description": "Ocultar ingresso (visível apenas na página secreta)",
    "validation": {
      "titleRequired": "O título é obrigatório",
      "descriptionRequired": "A descrição é obrigatória",
      "priceZero": "O preço não pode ser zero",
      "priceRequired": "O preço é obrigatório",
      "initialQuantityZero": "A quantidade inicial não pode ser zero",
      "maxQuantityLimit": "A quantidade máxima deve ser menor que a quantidade inicial",
      "maxQuantityZero": "A quantidade máxima não pode ser zero",
      "minQuantityLimit": "A quantidade mínima deve ser menor que a quantidade máxima e inicial",
      "minQuantityZero": "A quantidade mínima não pode ser zero",
      "categoryRequired": "A categoria é obrigatória",
      "productRequired": "O produto é obrigatório",
      "quantityRequired": "A quantidade é obrigatória",
      "quantityGreaterThanZero": "A quantidade deve ser maior que zero",
      "withProductRequired": "O produto é obrigatório quando iscomposite é verdadeiro",
      "fieldRequired": "Este campo é obrigatório",
      "vatRateRange": "A taxa de IVA deve estar entre 1 e 100"
    }
  },
  "accessRequests": {
    "request_access": "Solicitar Acesso",
    "request_access_title": "Solicitar Acesso de Administrador",
    "full_name": "Nome Completo",
    "email": "Email",
    "company_name": "Nome da Empresa",
    "position": "Cargo",
    "reason": "Por que você precisa de acesso?",
    "cancel": "Cancelar",
    "submit": "Enviar Solicitação",
    "submitting": "Enviando...",
    "please_fill_all_fields": "Por favor, preencha todos os campos obrigatórios",
    "invalid_email": "Por favor, insira um endereço de email válido",
    "error_submitting_request": "Erro ao enviar solicitação. Por favor, tente novamente.",
    "phone": "Número de Telefone",
    "phone_optional": "Opcional - Incluir código do país",
    "company_optional": "Opcional",
    "position_optional": "Opcional",
    "source": "Como você nos encontrou?",
    "invalid_phone": "Por favor, insira um número de telefone válido",
    "source_options": {
      "search_engine": "Motor de busca (Google, Bing, etc.)",
      "social_media": "Redes sociais",
      "friend": "Amigo ou colega",
    }

  },
  "price_deformation": {
    "name_required": "Nome é obrigatório",
    "deformation_type_required": "Tipo de deformação é obrigatório",
    "value_required": "Valor é obrigatório",
    "value_positive": "O valor deve ser positivo",
    "invalid_value": "Valor inválido",
    "domain_required": "Pelo menos um domínio é necessário",
    "edit_price": "Editar deformação de preço",
    "new_price": "Nova deformação de preço",
    "edit_access": "Editar restrição de acesso",
    "new_access": "Nova restrição de acesso",
    "name": "Nome",
    "description": "Descrição",
    "active": "Ativo",
    "deformation_type": "Tipo de deformação",
    "percentage": "Porcentagem",
    "fixed_amount": "Valor fixo",
    "deformation_value": "Valor da deformação",
    "email_domains": "Domínios de email",
    "add_domain": "Adicionar domínio",
    "priority": "Prioridade",
    "cancel": "Cancelar",
    "update": "Atualizar",
    "create": "Criar",
    "advertisement": "Publicidade",
    "other": "Outro",
    "condition_type": "Tipo de restrição",
    "email_domain_type": "Domínio de email",
    "email_address_type": "Endereço de email",
    "email_addresses": "Endereços de email",
    "add_email": "Adicionar email",
    "import_csv": "Importar de CSV",
    "email_required": "Pelo menos um endereço de email é necessário",
    "emails_count": "{{count}} endereços de email adicionados",
    "max_tickets_per_email": "Máximo de ingressos por e-mail",
    "max_tickets_placeholder": "Deixe vazio para ilimitado",
    "max_tickets_helper": "Limite quantos ingressos um cliente pode comprar com o mesmo e-mail",
    "max_tickets_info": "Definir para limitar o número de ingressos que podem ser comprados com um único endereço de e-mail. Deixe vazio para sem limite.",
    "min_tickets_value": "O valor mínimo é 1",
    "integer_tickets_value": "Por favor, insira um número inteiro"
  },
  "single_ticket": "Ingresso",
  "multiple_tickets": "Ingressos",
  "event_sales_status": "Status de vendas do evento",
  "event_closed_for_sales": "Este evento está atualmente fechado para vendas",
  "event_open_for_sales": "Este evento está aberto para vendas",
  "open_sales": "Abrir vendas",
  "close_sales": "Fechar vendas",
  "confirm_open_sales": "Confirmar abertura de vendas",
  "confirm_close_sales": "Confirmar fechamento de vendas",
  "open_sales_confirmation_message": "Tem certeza que deseja abrir as vendas para este evento? Isso permitirá que os usuários façam novas reservas.",
  "close_sales_confirmation_message": "Tem certeza que deseja fechar as vendas para este evento? Isso impedirá que os usuários façam novas reservas.",
  "event_closed_warning": "As vendas estão atualmente fechadas para este evento. Nenhuma nova reserva pode ser feita até que as vendas sejam reabertas.",
  "Edit_Messages": "Editar mensagens",
  "Email_Preview": "Pré-visualização de e-mail",
  "access_restrictions": "Restrições de acesso",
  "hidden_links": "Links ocultos",
  "tickets_count": "Número de ingressos",
  "stop_sell_time": "A venda para às",
  "drag_drop_instruction": "Arraste e solte para reordenar os ingressos",
  "create_tickets": "Crie ingressos para seu evento",
  "access_restriction_title": "Restrição de acesso",
  "access_restriction_description": "Gerencie quem pode acessar seus ingressos",
  "add_access_restriction": "Adicionar restrição de acesso",
  "inactive": "Inativo",
  "priority": "Prioridade",
  "allowed_domains": "Domínios permitidos",
  "delete_confirmation": "Tem certeza que deseja excluir esta restrição de acesso?",
  "Error_loading_email_template": "Erro ao carregar modelo de e-mail",
  "No_email_template_found": "Nenhum modelo de e-mail encontrado",
  "No_preview_available": "Nenhuma pré-visualização disponível",
  "Edit_success": "Edição bem-sucedida!",
  "Edit_failed": "Falha na edição!",
  "Loading": "Carregando...",
  "Domain-based": "Baseado em domínio",
  "Email-based": "Baseado em email",
  "Allowed Values": "Valores permitidos",
  "email addresses": "endereços de email",
  "Access restrictions limit ticket purchases to specific email domains or addresses. Multiple restrictions can be active at the same time.": "As restrições de acesso limitam a compra de ingressos a domínios ou endereços de email específicos. Várias restrições podem estar ativas ao mesmo tempo.",
  "Access restrictions limit ticket purchases to specific email domains or addresses. Only one restriction can be active at a time.": "As restrições de acesso limitam a compra de ingressos a domínios ou endereços de email específicos. Apenas uma restrição pode estar ativa de cada vez.",
  "Allowed Emails": "Emails permitidos",
  "more": "mais",
  "No access restrictions have been created yet. Create one to limit ticket purchases to specific email domains or addresses.": "Ainda não foram criadas restrições de acesso. Crie uma para limitar a compra de ingressos a domínios ou endereços de email específicos.",
  "Are you sure you want to delete this access restriction?": "Tem certeza de que deseja excluir esta restrição de acesso?",
  "Successfully added": "Adicionado com sucesso",
  "Successfully updated": "Atualizado com sucesso",
  bus: // Portuguese translations
  {
    "title": "Gestão de Ônibus",
    "newTrip": "Nova Viagem de Ônibus",
    "active": "Ativos",
    "past": "Passados",
    "all": "Todos",
    "name": "Nome",
    "description": "Descrição",
    "route": "Rota",
    "departureLocation": "Local de Partida",
    "arrivalLocation": "Local de Chegada",
    "duration": "Duração",
    "minutes": "minutos",
    "capacity": "Capacidade",
    "price": "Preço",
    "currency": "Moeda",
    "isRoundTrip": "Ida e Volta",
    "schedules": "Horários",
    "noTripsFound": "Nenhuma viagem de ônibus encontrada",
    "noSchedulesFound": "Nenhum horário encontrado para esta viagem",
    "actions": "Ações",
    "addSchedule": "Adicionar Horário",
    "edit": "Editar Viagem",
    "delete": "Excluir Viagem",
    "confirmDelete": "Tem certeza que deseja excluir esta viagem de ônibus?",
    "confirmDeleteSchedule": "Tem certeza que deseja excluir este horário?",
    "departureDate": "Data de Partida",
    "departureTime": "Hora de Partida",
    "returnDate": "Data de Retorno",
    "returnTime": "Hora de Retorno",
    "availableSeats": "Assentos Disponíveis",
    "status": "Status",
    "createSuccess": "Viagem de ônibus criada com sucesso",
    "createError": "Erro ao criar viagem de ônibus",
    "updateSuccess": "Viagem de ônibus atualizada com sucesso",
    "updateError": "Erro ao atualizar viagem de ônibus",
    "deleteSuccess": "Viagem de ônibus excluída com sucesso",
    "deleteError": "Erro ao excluir viagem de ônibus",
    "scheduleAddSuccess": "Horário adicionado com sucesso",
    "scheduleAddError": "Erro ao adicionar horário",
    "scheduleDeleteSuccess": "Horário excluído com sucesso",
    "scheduleDeleteError": "Erro ao excluir horário",
    "fetchError": "Erro ao buscar viagens de ônibus",
    "import": "Importar viagens",
    "importing": "Importando viagens de ônibus",
    "importSuccess": "Viagens de ônibus importadas com sucesso",
    "importError": "Falha ao importar viagens de ônibus",
    "parseError": "Falha ao analisar arquivo JSON",
    "readError": "Falha ao ler o arquivo",
    "invalidFileType": "Tipo de arquivo inválido. Por favor, selecione um arquivo JSON",
    "importBusTrips": "Importar viagens de ônibus",
    "previewImport": "Pré-visualização da importação",
    "foundTrips": "{count} viagens encontradas no arquivo",
    "details": "Detalhes",
    "roundTrip": "Ida e volta",
    "showSchedules": "Mostrar horários",
    "hideSchedules": "Esconder horários",
    "importToDatabase": "Importar para o banco de dados",
    "dropzoneInstructions": "Carregue um arquivo JSON com dados de viagens de ônibus",
    "dragAndDrop": "Arraste e solte seu arquivo aqui",
    "dropFileHere": "Solte o arquivo aqui",
    "orClickToSelect": "ou clique para selecionar um arquivo",
    "onlyJsonFiles": "Apenas arquivos JSON são suportados",
    "direction": "Direção",
    "tripNumber": "Viagem N°",
    "filterByDirection": "Filtrar por direção",
    "allDirections": "Todas as direções",
    "outbound": "Ida",
    "return": "Volta",
    "aller": "Ida",
    "retour": "Volta",
    "allSchedules": "Todos os horários",
    "outboundSchedules": "Horários de ida",
    "returnSchedules": "Horários de volta",
    "outboundTripNote": "Esta viagem de ida irá de {from} para {to} com duração de {duration} minutos.",
    "returnTripNote": "Esta viagem de volta irá de {from} para {to} com duração de {duration} minutos.",
    "passengerCount": "Passageiros",
    "bookingSummary": "Resumo de Reservas",
    "overallFillRate": "Taxa de Ocupação Global",
    "totalPassengers": "Total de Passageiros",
    "trips": "viagens",
    "passengers": "passageiros",
    "bookingLegend": "Legenda de Status de Reserva",
    "plentySeats": "Muitos assentos disponíveis",
    "fillingUp": "Enchendo",
    "almostFull": "Quase cheio",
    "scheduleManager": "Gerenciador de Horários",
    "selectBus": "Selecionar Ônibus",
    "seats": "lugares",
    "viewSchedules": "Ver Horários",
    "editSchedule": "Editar Horário",
    "statusActive": "Ativo",
    "statusAvailable": "Disponível",
    "statusFull": "Lotado",
    "statusCancelled": "Cancelado",
    "scheduleUpdateSuccess": "Horário atualizado com sucesso",
    "scheduleUpdateError": "Erro ao atualizar o horário",
    "totalRevenue": "Receita total",
    "occupancyRate": "Taxa de ocupação",
    "busStats": "Estatísticas de ônibus",
    "activeBuses": "Ônibus ativos",
    "totalSales": "Vendas totais",
    "fillRate": "Taxa de ocupação",
    "totalCapacity": "Capacidade total",
    "allBuses": "Todos os ônibus",
    "observation": "Observação",
    "seatsLeft": "lugares restantes",
    "customMessage": "Mensagem para o cliente",
    "customMessagePlaceholder": "Você pode reservar passagens de ônibus em qualquer direção. As passagens de retorno são gratuitas quando você compra uma passagem de ida.",
    "customMessageHelp": "Esta mensagem será exibida aos clientes ao selecionar ônibus para esta viagem."

  },
  // Role translations
  roles: {
    Owner: 'Proprietário',
    organizer: 'Organizador',
    promoter: 'PromoBooster',
    ambassador: 'Embaixador',
    bouncer: 'Segurança',
    staff: 'Equipe'
  },
  join_instadrink: "Junte-se ao Instadrink",
  become_part_of_our_community: "Torne-se parte da nossa comunidade",
  i_would_like_to_invite_you_to_join_my_network: "Gostaria de convidá-lo para se juntar à minha rede!",
  loading_public_invite: "Carregando link de convite...",
  join_network_now: "Entrar na Rede",
  please_fix_form_errors: "Por favor, corrija os erros no formulário",
  thank_you_for_joining_my_network: "Obrigado por se juntar à minha rede 🥂",
  invitation_request_sent_successfully_check_your_email_finish_registration: "Solicitação de convite enviada com sucesso. Por favor, verifique seu email 📧 para finalizar o registro.",
  i_am_a: 'Função',
  processing_invitation_request: "Processando solicitação de convite...",
  // Invitation translations
  invitation: {
    you_are_invited_as: 'Você está convidado como',
    invitation_date: 'Data do convite',
    invitation_details: 'Detalhes do convite',
    invitation_preview: 'Pré-visualização do convite',
    please_fix_form_errors: 'Por favor, corrija os erros do formulário',
    error_loading_invitation: 'Erro ao carregar o convite',
    you_can_now_login: 'Você pode agora fazer login',
    redirecting_to_dashboard: 'Redirecionando para o painel...',
    logging_in_automatically: 'Fazendo login automaticamente...',
    go_to_login: 'Ir para o login',
    account_created_successfully: 'Conta criada com sucesso',
    by: 'por',
    password_requirements: {
      min: 'Pelo menos 8 caracteres',
      uppercase: 'Pelo menos uma letra maiúscula',
      lowercase: 'Pelo menos uma letra minúscula',
      number: 'Pelo menos um número',
      special: 'Pelo menos um caractere especial'
    }
  },
  "tickets_in_reservation": "Ingressos na reserva",
  "payment_id": "ID de pagamento",
  "origin": "Origem",
  "bus_tickets": "Bilhetes de ônibus",
  "type": "Tipo",
  "route": "Rota",
  "departure_time": "Hora de partida",
  "outbound": "Ida",
  "return": "Volta",
  "not_specified": "Não especificado",
  "invalid_date": "Data inválida",
  "total_bus_tickets": "Total de bilhetes de ônibus",
  "preordered": "Pré-encomendado",
  "refunded": "Reembolsado",
  "mixed": "Misto",
  "sent": "Enviado",
  "not_sent": "Não enviado",
  "view_details": "Ver detalhes",
  "reservation_details": "Detalhes da Reserva",
  "reservation_id": "ID da Reserva",
  "client": "Cliente",
  "email": "Email",
  "phone": "Telefone",
  "payment_information": "Informações de Pagamento",
  "total_tickets": "Total de Bilhetes",
  
  "notes": "Notas",

  "edit_reservation": "Editar Reserva",
  "edit_ticket": "Editar Bilhete",
  "edit_client_information": "Editar Informações do Cliente",
  "edit_ticket_info": "Editar Informações do Bilhete",
  "edit_reservation_info": "Editar Informações da Reserva",
  "edit_form": "Formulário",
  "select_ticket_to_edit": "Selecione um bilhete para editar",
  "client_information": "Informações do cliente",
  "ticket_holder_information": "Informações do portador do bilhete",
  "note_client_email_unchanged": "Nota: O e-mail do cliente permanece inalterado",
  "update_failed": "Atualização falhou"
};

export default pt;