// src/redux/api/hiddenLinks.api.ts

import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { 
  collection, 
  getDocs, 
  addDoc, 
  updateDoc, 
  deleteDoc,
  doc,
  Timestamp,
  query,
  where 
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { DB, FUNCTIONS } from 'src/contexts/FirebaseContext';
import { 
  HiddenLink, 
  CreateHiddenLinkRequest, 
  UpdateHiddenLinkRequest, 
  DeleteHiddenLinkRequest,
  HiddenLinkResponse 
} from 'src/@types/hidden_links';

export const HiddenLinksApi = createApi({
  reducerPath: 'hiddenLinksApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['HiddenLinks'],
  endpoints: (builder) => ({
    fetchHiddenLinks: builder.query<HiddenLink[], string>({
      queryFn: async (eventId) => {
        try {
          const linksRef = collection(DB, 'events', eventId, 'hiddenLinks');
          const snapshot = await getDocs(linksRef);
          
          const links = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as HiddenLink[];

          return { data: links };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      providesTags: ['HiddenLinks']
    }),

    createHiddenLink: builder.mutation<HiddenLinkResponse, CreateHiddenLinkRequest>({
        queryFn: async ({ eventId, name, ticketIds }) => {
          try {
            // First create the hidden link document to get its ID
            const linksRef = collection(DB, 'events', eventId, 'hiddenLinks');
            const newLink: Omit<HiddenLink, 'id' | 'url'> = {
              name,
              ticketIds,
              createdAt: Timestamp.now(),
              isActive: true,
              eventId
            };
      
            // Add document to get the ID
            const docRef = await addDoc(linksRef, newLink);
            
            // Create deep link using Cloud Function with the hidden link ID
            const createDeeplink = httpsCallable(FUNCTIONS, 'createDeepLink');
            const result = await createDeeplink({
              id: eventId,
              tier: 'hidden-tickets',
              isHidden: true,
              app: 'tickets',
              hiddenLinkId: docRef.id // Pass the document ID
            });
      
            const response = result.data as { haserror: boolean; newdoc?: string; error?: any };
      
            if (response.haserror || !response.newdoc) {
              // Clean up the created document if deep link creation fails
              await deleteDoc(docRef);
              throw new Error(response.error || 'Failed to create deep link');
            }
      
            // Update the hidden link document with the URL
            await updateDoc(docRef, {
              url: response.newdoc
            });
      
            return { 
              data: { 
                success: true, 
                data: { 
                  id: docRef.id, 
                  ...newLink,
                  url: response.newdoc 
                } 
              }
            };
          } catch (error: any) {
            return { 
              error: { 
                success: false, 
                error: error.message 
              }
            };
          }
        },
        invalidatesTags: ['HiddenLinks']
      }),
    updateHiddenLink: builder.mutation<HiddenLinkResponse, UpdateHiddenLinkRequest>({
      queryFn: async ({ eventId, linkId, name, ticketIds, isActive }) => {
        try {
          const linkRef = doc(DB, 'events', eventId, 'hiddenLinks', linkId);
          const updateData = {
            name,
            ticketIds,
            isActive
          };

          await updateDoc(linkRef, updateData);

          return { 
            data: { 
              success: true,
              data: {
                id: linkId,
                ...updateData,
                eventId
              } as HiddenLink
            }
          };
        } catch (error: any) {
          return { 
            error: { 
              success: false, 
              error: error.message 
            }
          };
        }
      },
      invalidatesTags: ['HiddenLinks']
    }),

    deleteHiddenLink: builder.mutation<HiddenLinkResponse, DeleteHiddenLinkRequest>({
      queryFn: async ({ eventId, linkId }) => {
        try {
          await deleteDoc(doc(DB, 'events', eventId, 'hiddenLinks', linkId));
          return { 
            data: { 
              success: true 
            }
          };
        } catch (error: any) {
          return { 
            error: { 
              success: false, 
              error: error.message 
            }
          };
        }
      },
      invalidatesTags: ['HiddenLinks']
    })
  })
});

export const {
  useFetchHiddenLinksQuery,
  useCreateHiddenLinkMutation,
  useUpdateHiddenLinkMutation,
  useDeleteHiddenLinkMutation
} = HiddenLinksApi;