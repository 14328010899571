// src/redux/api/ticketCategories.api.ts

import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { 
  collection, 
  getDocs, 
  addDoc, 
  updateDoc, 
  deleteDoc,
  doc,
  Timestamp,
  query,
  orderBy
} from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { 
  TicketCategory, 
  CreateTicketCategoryRequest, 
  UpdateTicketCategoryRequest, 
  DeleteTicketCategoryRequest,
  TicketCategoryResponse 
} from 'src/@types/ticket_category';

export const TicketCategoriesApi = createApi({
  reducerPath: 'ticketCategoriesApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['TicketCategories'],
  endpoints: (builder) => ({
    fetchTicketCategories: builder.query<TicketCategory[], string>({
      queryFn: async (eventId) => {
        try {
          const categoriesRef = collection(DB, 'events', eventId, 'ticketCategories');
          const q = query(categoriesRef, orderBy('order', 'asc'));
          const snapshot = await getDocs(q);
          
          const categories = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as TicketCategory[];

          return { data: categories };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      providesTags: ['TicketCategories']
    }),

    createTicketCategory: builder.mutation<TicketCategoryResponse, CreateTicketCategoryRequest>({
      queryFn: async ({ eventId, name, description, color }) => {
        try {
          // Get existing categories to determine order
          const categoriesRef = collection(DB, 'events', eventId, 'ticketCategories');
          const snapshot = await getDocs(categoriesRef);
          const existingCategories = snapshot.docs.map(doc => doc.data());
          
          const nextOrder = existingCategories.length > 0 
            ? Math.max(...existingCategories.map(cat => cat.order || 0)) + 1 
            : 1;

          // Create new category
          const newCategory: Omit<TicketCategory, 'id'> = {
            name,
            description: description || "",
            eventId,
            order: nextOrder,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            color: color || "#1976d2", // Default blue
            isActive: true
          };

          const docRef = await addDoc(categoriesRef, newCategory);
          
          return { 
            data: { 
              success: true, 
              data: { 
                id: docRef.id,
                ...newCategory 
              } as TicketCategory
            }
          };
        } catch (error: any) {
          return { 
            error: { 
              success: false, 
              error: error.message 
            }
          };
        }
      },
      invalidatesTags: ['TicketCategories']
    }),

    updateTicketCategory: builder.mutation<TicketCategoryResponse, UpdateTicketCategoryRequest>({
      queryFn: async ({ eventId, categoryId, name, description, order, color, isActive }) => {
        try {
          const categoryRef = doc(DB, 'events', eventId, 'ticketCategories', categoryId);
          
          const updateData: Partial<TicketCategory> = {
            updatedAt: Timestamp.now()
          };
          
          if (name !== undefined) updateData.name = name;
          if (description !== undefined) updateData.description = description;
          if (order !== undefined) updateData.order = order;
          if (color !== undefined) updateData.color = color;
          if (isActive !== undefined) updateData.isActive = isActive;

          await updateDoc(categoryRef, updateData);

          return { 
            data: { 
              success: true,
              data: {
                id: categoryId,
                ...updateData,
                eventId,
              } as TicketCategory
            }
          };
        } catch (error: any) {
          return { 
            error: { 
              success: false, 
              error: error.message 
            }
          };
        }
      },
      invalidatesTags: ['TicketCategories']
    }),

    deleteTicketCategory: builder.mutation<TicketCategoryResponse, DeleteTicketCategoryRequest>({
      queryFn: async ({ eventId, categoryId }) => {
        try {
          await deleteDoc(doc(DB, 'events', eventId, 'ticketCategories', categoryId));
          return { 
            data: { 
              success: true 
            }
          };
        } catch (error: any) {
          return { 
            error: { 
              success: false, 
              error: error.message 
            }
          };
        }
      },
      invalidatesTags: ['TicketCategories']
    }),

    reorderTicketCategories: builder.mutation<TicketCategoryResponse, {eventId: string, categories: TicketCategory[]}>({
      queryFn: async ({ eventId, categories }) => {
        try {
          // Update each category with its new order
          const updatePromises = categories.map((category, index) => {
            const categoryRef = doc(DB, 'events', eventId, 'ticketCategories', category.id!);
            return updateDoc(categoryRef, { 
              order: index + 1,
              updatedAt: Timestamp.now()
            });
          });
          
          await Promise.all(updatePromises);
          
          return { 
            data: { 
              success: true 
            }
          };
        } catch (error: any) {
          return { 
            error: { 
              success: false, 
              error: error.message 
            }
          };
        }
      },
      invalidatesTags: ['TicketCategories']
    })
  })
});

export const {
  useFetchTicketCategoriesQuery,
  useCreateTicketCategoryMutation,
  useUpdateTicketCategoryMutation,
  useDeleteTicketCategoryMutation,
  useReorderTicketCategoriesMutation
} = TicketCategoriesApi;