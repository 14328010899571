import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, IconButton, Tooltip } from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import MyAvatar from '../../../components/MyAvatar';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props)
{
  const { t } = useTranslation();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const lang = user.lang || 'fr';
  const userId = user.uid;
  const url_constant = `/auth/public-invitation?ail=${userId}&lng=${lang}`;
  // domain including localhost or IP address
  const islocalhost = window.location.hostname === 'localhost';
  const domain = islocalhost ? 'localhost:3000' : window.location.hostname;

  const url = islocalhost ? `http://${domain}${url_constant}` : `https://${domain}${url_constant}`;
  console.log('URL:', url);

  const handleCopyLink = async (e: React.MouseEvent) =>
  {
    e.preventDefault(); // Prevent navigation
    try
    {
      await navigator.clipboard.writeText(url);
      enqueueSnackbar('Link copied to clipboard!', { variant: 'success' });
    } catch (err)
    {
      enqueueSnackbar('Failed to copy link', { variant: 'error' });
    }
  };

  const handleShare = async (e: React.MouseEvent) =>
  {
    e.preventDefault(); // Prevent navigation
    if (navigator.share)
    {
      try
      {
        await navigator.share({
          title: 'Public Invitation',
          text: 'Join my network!',
          url: url,
        });
      } catch (err)
      {
        console.error('Error sharing:', err);
      }
    } else
    {
      handleCopyLink(e);
    }
  };

  return (
    <Link underline="none" color="inherit" component={RouterLink} to={PATH_DASHBOARD.user.account}>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <MyAvatar />

        <Box
          sx={{
            ml: 1,
            mr: 1,
            flex: 1,
            minWidth: 0,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {t(user?.role)}
          </Typography>
        </Box>

        {!isCollapse && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 0.25,
              ml: 1,
            }}
          >
            <Tooltip title="Copy invitation link" placement="left">
              <IconButton
                onClick={handleCopyLink}
                size="small"
                sx={{
                  color: 'text.secondary',
                  padding: 0.5,
                }}
              >
                <Iconify icon="eva:copy-outline" width={16} height={16} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Share invitation" placement="left">
              <IconButton
                onClick={handleShare}
                size="small"
                sx={{
                  color: 'text.secondary',
                  padding: 0.5,
                }}
              >
                <Iconify icon="eva:share-outline" width={16} height={16} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </RootStyle>
    </Link>
  );
}
